import { Box, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import Chip from "./../shared/components/Chip";
import NewsletterSection from "./../main/NewsletterSection";
// import { capturePayment, createPayment } from "../services/authentication";
import MembershipPlans from "./setting/tabs/membership/membershipDialog/MembershipPlans";
import { getUserIdFromCookies } from "../utils/extensions";
import { getUserActiveSubscription } from "../services/component";

type Props = {};

const Pricing = (props: Props) => {
  const [activePlan, setActivePlan] = useState<any>({});
  const [activeSubscriptions, setActiveSubscriptions] = useState<any>({});

  const userId = getUserIdFromCookies();
  const getSubscriptions = async () => {
    await getUserActiveSubscription(userId)
      .then((res: any) => {
        setActivePlan(res?.activeSubscriptionName);
        setActiveSubscriptions(res?.userSubscriptions);
      })
      .catch((err) => console.log(err));
  };
  useEffect(() => {
    window.scrollTo(0, 0);
    getSubscriptions();
  }, []);

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        gap: "50px",
        width: "100%",
        paddingY: { xs: "24px", sm: "40px", md: "80px" },
      }}
    >
      {/* ------Header Section------ */}
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          gap: "24px",
          textAlign: "center",
          alignItems: "center",
          paddingX: { xs: "24px", sm: "40px", md: "80px" },
        }}
      >
        <Chip
          sx={{
            height: "48px",
            borderRadius: "79px",
            width: "182px",
          }}
          title="Your Go-To Guide"
        />
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            textAlign: "center",
            gap: "16px",
          }}
        >
          <Typography
            sx={{
              color: "white",
              fontWeight: "600",
              fontSize: "58px",
              width: { xs: "100%", sm: "100%", md: "100%", lg: "100%" },
              lineHeight: "110%",
              background:
                "linear-gradient(81deg, rgba(255,255,255,1) 0%, rgba(48,48,48,1) 100%)",
              WebkitBackgroundClip: "text",
              WebkitTextFillColor: "transparent",
            }}
            variant="h1"
          >
            Flexible Plans to Fit Your Needs
          </Typography>
          <Typography
            variant="h4"
            sx={{
              color: "rgba(255, 255, 255, 0.5)",
              fontWeight: "400",
              width: { xs: "100%", sm: "80%", md: "60%", lg: "60%" },
              fontSize: "18px !important",
            }}
          >
            By choosing Ulwiki, you will streamline your design process with
            essential tools, advanced features, and efficient, time-saving
            solutions.
          </Typography>
        </Box>
      </Box>

      {/* ------Pricing Section------ */}
      <MembershipPlans
        activePlan={activePlan}
        activeSubscriptions={activeSubscriptions}
      />

      {/* -----Newsletter Section----- */}
      <Box
        sx={{ width: "100%", paddingX: { xs: "24px", sm: "40px", md: "80px" } }}
      >
        <NewsletterSection />
      </Box>
    </Box>
  );
};

export default Pricing;

export interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

export function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <Box
      sx={{ width: "100%" }}
      className="HideScrollbar"
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ flex: 1 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </Box>
  );
}
