import React, { useEffect, useState } from "react";
import { Box, Typography, Button, InputBase } from "@mui/material";
import { useNavigate } from "react-router-dom";
import PromoCodeCongratulationPopup from "./PromoCodeCongratulationsPopup";
import { applyCoupon } from "../../services/authentication";
import { toastError, toastSuccess } from "../../utils/ToastMessage";
import { HomeCoverBg } from "../../assets/images/image";
import ErrorMessageIcon from "../../assets/icons/ErrorMessageIcon/ErrorMessageIcon";
import DefaultLoading from "../../shared/Loading/DefaultLoading";
import LoadingIndicator from "../../shared/Loading/LoadingIndicator";

const PromoCodePage = () => {
  const [activeCongratulationPopup, setActiveCongratulationPopup] =
    useState(false);
  const [promoCode, setPromoCode] = useState<string>("");
  const [error, setError] = useState<string | null>(null);
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();

  const handleApplyCoupon = async () => {
    setError(null);
    setIsLoading(true);
    await applyCoupon({ promoCode })
      .then((res: any) => {
        if (res?.response?.data?.success || res?.success) {
          toastSuccess("Successfully reset password.", res?.message);
          setActiveCongratulationPopup(true);
          setPromoCode("");
        } else {
          toastError(
            "Ops! Something went wrong.",
            res?.response?.data?.message
          );
          setPromoCode(promoCode);
          setError(res?.response?.data?.message || "Invalid coupon code.");
        }
      })
      .catch((err: any) => {
        toastError(
          "Ops! Something went wrong.",
          "Failed to reset the password."
        );
        setError("Failed to apply the coupon.");
        setPromoCode(promoCode);
      });

    setIsLoading(false);
  };

  const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === "Enter") {
      handleApplyCoupon();
    }
  };
  //Stop scrolling when popup is open
  useEffect(() => {
    if (activeCongratulationPopup) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "auto";
    }

    return () => {
      document.body.style.overflow = "auto";
    };
  }, [activeCongratulationPopup]);

  return (
    <Box
      sx={{
        display: "flex",
        width: "100%",
        justifyContent: "center",
        maxWidth: "100%",
        height: "100%",
      }}
    >
      <img className="background-image" alt="background" src={HomeCoverBg} />
      <Box
        sx={{
          minHeight: "656px",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
          margin: "0px 24px",
        }}
      >
        {activeCongratulationPopup && (
          <PromoCodeCongratulationPopup
            activeDialog={activeCongratulationPopup}
            setActiveDialog={setActiveCongratulationPopup}
          />
        )}
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            width: "auto",
            height: "48px",
            padding: "12px 30px",
            borderRadius: "79px",
            backgroundColor: "#1B1B1B",
            textAlign: "center",
            border: "1px solid #3D3D3D",
            boxSizing: "border-box",
          }}
        >
          <Typography
            sx={{
              fontSize: { xs: "12px", sm: "15px", lg: "16px" },
              color: "#8D8D8D",
              fontWeight: 400,
            }}
          >
            Have a Promo Code?
          </Typography>
        </Box>
        <Typography
          component="h1"
          sx={{
            marginTop: "37px",
            marginBottom: "12px",
            width: "100%",
            maxWidth: "652px",
            fontSize: { xs: "24px", sm: "32px" },
            fontWeight: 600,
            textAlign: "center",
          }}
        >
          <span
            style={{
              background: "linear-gradient(to right, #FEFEFE, #757575)",
              WebkitBackgroundClip: "text",
              backgroundClip: "text",
              color: "transparent",
            }}
          >
            Got a Code? Let’s Unlock Some Magic!
          </span>
          <span style={{ color: "inherit" }}> 🪄</span>
        </Typography>
        <Typography
          component="h4"
          sx={{
            width: "100%",
            maxWidth: "635px",
            fontSize: { xs: "14px", sm: "18px" },
            fontWeight: 400,
            color: "#888888",
            textAlign: "center",
          }}
        >
          Pop in your discount code and unleash 1,000+ premium Figma components.
          Your designs are about to get a serious upgrade! 🚀
        </Typography>
        <Box
          sx={{
            marginTop: "40px",
            width: "100%",
            maxWidth: "481px",
            display: "flex",
            gap: "12px",
            flexDirection: { xs: "column", sm: "row" },
            justifyContent: "center",
            marginX: "auto",
          }}
        >
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              width: "100%",
              borderRadius: "12px",
              maxWidth: "480px",
            }}
          >
            <InputBase
              onKeyDown={handleKeyDown}
              placeholder="Enter your code here..."
              onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                setPromoCode(e.target.value)
              }
              value={promoCode || ""}
              required={true}
              sx={{
                width: "100%",
                height: "54px",
                padding: "3px 16px",
                borderRadius: "14px",
                backgroundColor: "#FFFFFF0F",
                color: error ? "#E25454" : "#FFFFFF",
                border: error ? "1px solid #E25454" : "1px solid #3D3D3D",
                fontSize: "16px",
                fontWeight: 400,
                lineHeight: "24px",
                outline: "none",
                "&::placeholder": {
                  color: error ? "#8F8F8F" : "#8F8F8F",
                  fontWeight: 400,
                  lineHeight: "24px",
                },
              }}
            />
            <Typography
              className="err_field"
              id="emailNotExist"
              color="#E25454"
              variant="body2"
              display={"flex"}
              gap={"4px"}
              marginTop={"12px"}
              alignItems={"center"}
              sx={{ width: "100%" }}
              fontWeight={"400"}
              fontSize="14px"
              lineHeight={"16px"}
              letterSpacing={"8%"}
            >
              {error && (
                <>
                  <span>
                    <ErrorMessageIcon />
                  </span>
                  {error}
                </>
              )}
            </Typography>
          </Box>

          <Button
            sx={{
              width: "100%",
              maxWidth: { xs: "100%", sm: "131px" },
              height: "54px",
              borderRadius: "14px",
              backgroundColor: "#CCFF00",
              border: "1px solid #718B08",
              color: "#000000",
              padding: "16px 41px",
              fontSize: "18px",
              fontFamily: "'Inter Tight', sans-serif",
              fontWeight: 700,
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              cursor: "pointer",
              "&:hover": {
                backgroundColor: "#BBDD00",
              },
            }}
            onClick={handleApplyCoupon}
          >
            {!isLoading ? (
              "Apply"
            ) : (
              <Box
                sx={{
                  paddingTop: "10px",
                }}
              >
                <DefaultLoading />
              </Box>
            )}
          </Button>
        </Box>
      </Box>
    </Box>
  );
};

export default PromoCodePage;
