import React from 'react'

const MinusIcon = ({color,opacity}) => {
  return (
    <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M14.25 8.25307H9.75C9.75 8.25307 8.25 8.25268 8.25 8.25V8.25268L3.75 8.25307C3.55109 8.25307 3.36032 8.33208 3.21967 8.47274C3.07902 8.61339 3 8.80415 3 9.00307C3 9.20198 3.07902 9.39274 3.21967 9.5334C3.36032 9.67405 3.55109 9.75307 3.75 9.75307H8.25H9.75H14.25C14.4489 9.75307 14.6397 9.67405 14.7803 9.5334C14.921 9.39274 15 9.20198 15 9.00307C15 8.80415 14.921 8.61339 14.7803 8.47274C14.6397 8.33208 14.4489 8.25307 14.25 8.25307Z" fill={color ||"white"} fill-opacity={opacity ||"0.5"}/>
</svg>

  )
}

export default MinusIcon
