import { Box, Typography } from "@mui/material";
import React from "react";

type Props = {};

const PrivacyPolicy = (props: Props) => {
  const paragraphStyle: any = {
    fontWeight: "400",
    color: "rgba(255, 255, 255, 0.5)",
    lineHeight: "24px",
    marginBottom: "12px",
  };

  const headingStyle = {
    marginBottom: "18px",
    color: "white",
    fontWeight: "600",
  };

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        width: "100%",
      }}
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          padding: { xs: "24px", sm: "40px", md: "80px" },
          gap: { xs: "32px", sm: "60px" },
          width: "100%",
          maxWidth: "862px",
        }}
      >
        {/* -------Header Section------- */}
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: { xs: "flex-start", sm: "center" },
            textAlign: { xs: "start", sm: "center" },
            gap: "16px",
            width: "100%",
          }}
        >
          <Typography
            sx={{
              color: "white",
              fontWeight: "600",
              fontSize: "58px",
              width: "60%",
              lineHeight: "110%",
              background:
                "linear-gradient(81deg, rgba(255,255,255,1) 0%, rgba(48,48,48,1) 100%)",
              WebkitBackgroundClip: "text",
              WebkitTextFillColor: "transparent",
            }}
            variant="h1"
          >
            Privacy Policy
          </Typography>
        </Box>

        {/* -------Content section------- */}
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            width: "100%",
            gap: { xs: "24px", sm: "24px" },
          }}
        >
          <Box>
            <Typography variant="subtitle1" style={headingStyle}>
              UI Wiki Privacy Policy
            </Typography>
            <Typography
              variant="subtitle1"
              sx={{ color: "primary.main", fontWeight: "400", mb: "18px" }}
            >
              Effective Date: 15 November 2024
            </Typography>
            <Typography variant="subtitle1" style={paragraphStyle}>
              {`UI Wiki ("we," "us," or "our") is a product of Omega Orion Pvt. Ltd., a legal entity committed to protecting your privacy.  This Privacy Policy describes the information we collect, how we use it, and the rights you have regarding your data. By using UI Wiki, you consent to the practices described below.`}
            </Typography>
          </Box>
          <Box>
            <Typography variant="subtitle1" style={headingStyle}>
              Information We Collect
            </Typography>
            <Box
              component="ul"
              sx={{
                paddingLeft: "20px",
                marginBottom: "24px",
              }}
            >
              <li style={{ color: "white", marginBottom: "12px" }}>
                <Typography variant="subtitle1" sx={paragraphStyle}>
                  <strong>Email Address:</strong> We collect your email for
                  authentication, communication, and marketing purposes. This
                  allows us to send you updates, newsletters, and promotional
                  materials.
                </Typography>
              </li>
              <li style={{ color: "white" }}>
                <Typography variant="subtitle1" sx={paragraphStyle}>
                  <strong>First and Last Name:</strong> Your name is collected
                  to personalize your experience on the platform and within
                  email communications.
                </Typography>
              </li>
            </Box>
          </Box>
          <Box>
            <Typography variant="subtitle1" style={headingStyle}>
              Purpose of Data Collection
            </Typography>
            <Typography variant="subtitle1" sx={paragraphStyle}>
              We collect and use your information to:
            </Typography>
            <Box
              component="ul"
              sx={{ paddingLeft: "20px", marginBottom: "24px" }}
            >
              <li style={{ color: "white", marginBottom: "12px" }}>
                <Typography variant="subtitle1" sx={paragraphStyle}>
                  Authenticate and manage your account.
                </Typography>
              </li>
              <li style={{ color: "white", marginBottom: "12px" }}>
                <Typography variant="subtitle1" sx={paragraphStyle}>
                  Personalize your experience on UI Wiki.
                </Typography>
              </li>
              <li style={{ color: "white" }}>
                <Typography variant="subtitle1" sx={paragraphStyle}>
                  {`     Send updates and marketing materials (you may opt-out of
                  marketing communications at any time).`}
                </Typography>
              </li>
            </Box>
          </Box>
          <Box>
            <Typography variant="subtitle1" style={headingStyle}>
              Third-Party Services and Data Sharing
            </Typography>
            <Typography variant="subtitle1" sx={paragraphStyle}>
              {` We do not share your personal information (email, first name, last
              name) with any third parties for their own marketing or
              promotional use. However, we do use third-party services for
              essential operations:`}
            </Typography>
            <Box
              component="ul"
              sx={{ paddingLeft: "20px", marginBottom: "24px" }}
            >
              <li style={{ color: "white", marginBottom: "12px" }}>
                <Typography variant="subtitle1" sx={paragraphStyle}>
                  <strong>PayPal:</strong> We use PayPal for managing
                  subscription payments. PayPal may collect and store your
                  payment information according to its privacy policies, but UI
                  Wiki does not directly store or access this information.
                </Typography>
              </li>
              <li style={{ color: "white" }}>
                <Typography variant="subtitle1" sx={paragraphStyle}>
                  <strong>Google Analytics:</strong>{" "}
                  {`We use Google Analytics to
                  understand user engagement with the platform. Google Analytics
                  may collect anonymous usage data as per its policies. For
                  details on Google Analytics data collection practices, please
                  refer to Google’s Privacy Policy.`}
                </Typography>
              </li>
            </Box>
          </Box>
          <Box>
            <Typography variant="subtitle1" style={headingStyle}>
              User Rights
            </Typography>
            <Typography variant="subtitle1" sx={paragraphStyle}>
              You have the right to:
            </Typography>
            <Box
              component="ul"
              sx={{ paddingLeft: "20px", marginBottom: "24px" }}
            >
              <li style={{ color: "white", marginBottom: "12px" }}>
                <Typography variant="subtitle1" sx={paragraphStyle}>
                  Access and update your personal information at any time
                  through your account settings.
                </Typography>
              </li>
              <li style={{ color: "white" }}>
                <Typography variant="subtitle1" sx={paragraphStyle}>
                  Delete your account and associated information, if desired.
                </Typography>
              </li>
            </Box>
            <Typography variant="subtitle1" sx={paragraphStyle}>
              Please contact us if you would like to exercise any of these
              rights.
            </Typography>
          </Box>
          <Box>
            <Typography variant="subtitle1" style={headingStyle}>
              Cookies and Tracking Technologies
            </Typography>
            <Typography variant="subtitle1" sx={paragraphStyle}>
              UI Wiki does not currently use cookies. However, in the future, we
              may use cookies to improve user experience and platform
              functionality. This Privacy Policy will be updated to reflect any
              changes in our cookie practices.
            </Typography>
          </Box>
          <Box>
            <Typography variant="subtitle1" style={headingStyle}>
              Data Retention{" "}
            </Typography>
            <Typography variant="subtitle1" sx={paragraphStyle}>
              We retain your data for as long as your account is active. If you
              choose to delete your account, your data will be permanently
              removed from our systems.
            </Typography>
          </Box>
          <Box>
            <Typography variant="subtitle1" style={headingStyle}>
              Security Measures
            </Typography>
            <Typography variant="subtitle1" sx={paragraphStyle}>
              We take data security seriously and implement advanced measures to
              protect your information. Our security practices include:
            </Typography>
            <Box
              component="ul"
              sx={{ paddingLeft: "20px", marginBottom: "24px" }}
            >
              <li style={{ color: "white", marginBottom: "12px" }}>
                <Typography variant="subtitle1" sx={paragraphStyle}>
                  Encryption of sensitive data.
                </Typography>
              </li>
              <li style={{ color: "white", marginBottom: "12px" }}>
                <Typography variant="subtitle1" sx={paragraphStyle}>
                  Use of secure servers.
                </Typography>
              </li>
              <li style={{ color: "white", marginBottom: "12px" }}>
                <Typography variant="subtitle1" sx={paragraphStyle}>
                  API authentication tokens for accessing our services.
                </Typography>
              </li>
              <li style={{ color: "white" }}>
                <Typography variant="subtitle1" sx={paragraphStyle}>
                  Cloudflare’s advanced security solutions.
                </Typography>
              </li>
            </Box>
          </Box>
          <Box>
            <Typography variant="subtitle1" style={headingStyle}>
              Contact Information
            </Typography>
            <Typography variant="subtitle1" sx={paragraphStyle}>
              For any questions or concerns regarding this Privacy Policy,
              please contact us at contact@uiwiki.co.
            </Typography>
          </Box>
          <Box>
            <Typography variant="subtitle1" style={headingStyle}>
              Changes to This Privacy Policy
            </Typography>
            <Typography variant="subtitle1" sx={paragraphStyle}>
              We may update this Privacy Policy to reflect changes in our
              practices or for legal reasons. We encourage you to review this
              page periodically for the latest information on our privacy
              practices.
            </Typography>
          </Box>
        </Box>
        <Typography
          variant="subtitle1"
          sx={{
            color: "rgba(255, 255, 255, 0.5)",
            fontWeight: "400",
          }}
        >
          By using UI Wiki, you acknowledge that you have read and agree to this
          Privacy Policy.
        </Typography>
      </Box>
    </Box>
  );
};

export default PrivacyPolicy;
