import { Button, Box, Typography, useMediaQuery } from "@mui/material";
import NotFoundIcon from "../../assets/icons/404/NotFoundIcon";
import { useNavigate } from "react-router-dom";
import { useTheme } from "@mui/material/styles";

const NotFound = () => {
  const navigate = useNavigate();
  const theme = useTheme();
  const isXs = useMediaQuery(theme.breakpoints.down("sm"));
  return (
    <>
      <Box
        sx={{
          maxWidth: "1440px",
          margin: "auto",
          display: "flex",
          flexDirection: {
            xs: "column", // mobile
            sm: "column", // tablet
            md: "row", // desktop
          },
          justifyContent: {
            xs: "center",
            sm: "center",
            md: "flex-start",
          },
          gap: {
            xs: "16px",
            sm: "24px",
            md: "32px",
          },
          padding: {
            xs: "16px",
            sm: "24px",
            md: "0px",
            lg: "0px",
          },
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            margin: "80px 93px 206px 160px",
            maxWidth: "591px",
            width: "100%",
            // height: "332px",
            gap: "32px",
            "@media screen and (max-width: 1120px)": {
              margin: "80px 45px 206px 80px",
            },
            "@media screen and (max-width: 960px)": {
              margin: "40px auto 40px auto",
            },
          }}
        >
          <Box sx={{ display: "flex", flexDirection: "column", gap: "24px" }}>
            <Typography
              variant="body2"
              sx={{
                // width: "110px",
                width: "fit-content",
                height: "48px",
                borderRadius: "79px",
                color: "#8D8D8D",
                backgroundColor: "#1B1B1B",
                border: "1px solid #3D3D3D",
                padding: "12px 22px 12px 23px",
                fontSize: "15.4px",
                lineHeight: "24px",
                fontWeight: 400,
                // marginBottom: "24px",
                margin:{xs:"auto",sm:"0px"},
              }}
            >
              Error 404
            </Typography>
            <Box
              sx={{
                width: "100%",
                display: "flex",
                flexDirection: "column",
                gap: "24px",
              }}
            >
              <Typography
                // variant="h3"
                sx={{
                  // width: "591px",
                  // height:"128px",
                  minWidth: "340px",
                  width:"100%",
                  fontSize: "58px",
                  lineHeight: "64px",
                  fontWeight: 600,
                  background: "linear-gradient(to right, #FFFFFF, #7A7A7A)",
                  WebkitBackgroundClip: "text",
                  WebkitTextFillColor: "transparent",
                  "& span": {
                    WebkitBackgroundClip: "initial",
                    WebkitTextFillColor: "initial",
                    color: "inherit",
                  },
                  "@media screen and (max-width: 613px)": {
                    fontSize: "38px",
                    lineHeight: "50px",
                  },
                }}
              >
                Oops! You've Found a Design Black Hole!<span>🚀</span>
              </Typography>
              <Typography
                variant="body1"
                sx={{
                  maxWidth: "568px",
                  minWidth: "320px",
                  // height:"22px",
                  fontSize: "18px",
                  fontWeight: 400,
                }}
              >
                <span style={{ color: "rgba(255, 255, 255, 0.5)" }}>You're too awesome to get stuck here. Let's get back to
                creating!</span>
                <span>😎✨</span>
              </Typography>
            </Box>
          </Box>
          <Box>
            <Button
              sx={{
                // width: "250px",
                height: "54px",
                borderRadius: "16px",
                backgroundColor: "#CCFF00",
                border: "1px solid #718B08",
                padding: "16px 40px 16px 38px",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                fontSize: "16px",
                fontWeight: 500,
                lineHeight: "22px",
                color: "#000000",
                "&:hover": {
                  backgroundColor: "#BBDD00",
                },
                margin:{xs:"auto",sm:"0px"},
              }}
              onClick={() => navigate("/")}
            >
              Back to Homepage
            </Button>
          </Box>
        </Box>
        <Box
          sx={{
            margin: {
              xs: "40px 32px 40px 0",
              sm: "60px 80px 60px 0",
              md: "80px 116px 58px 0",
            },
            display: "flex",
            justifyContent: {
              xs: "center",
              sm: "center",
              md: "center",
            },
             "@media screen and (min-width: 960px) and (max-width: 1120px)": {
              margin: "80px 45px 58px 0px",
            },
            "@media screen and (max-width: 960px)": {
              margin: "0px auto 40px auto",
            },
          }}
        >
          {
            isXs ? <NotFoundIcon width="300" height="320"/> : <NotFoundIcon />
          }
        </Box>
      </Box>
    </>
  );
};

export default NotFound;
