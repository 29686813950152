import React, { useState } from "react";
import { Box, Typography } from "@mui/material";
import { CloseSharp } from "@mui/icons-material";
import { ButtonPrimary } from "../../../../../shared/components/button/Buttons";
import { colors } from "../../../../../utils/colors";

type Props = {
  activeDialog: any;
  setActiveSwitchPlan: any;
  handleConfirmUpdatePlan?: any;
  data?: any;
};

const ConfirmSwitchPlanDialog: React.FC<Props> = (props) => {
  const { data } = props;

  return (
    props?.activeDialog && (
      <>
        <Box
          sx={{
            position: "fixed", // Ensure the backdrop covers the entire screen
            top: 0,
            left: 0,
            width: "100vw",
            height: "100vh",
            bgcolor: "rgba(0, 0, 0, 0.60)",
            zIndex: 5000,
          }}
        >
          {/* Apply blur effect to only this background */}
          <Box
            sx={{
              width: "100vw",
              height: "100vh",
              border: "1px solid red",
              bgcolor: "rgba(0, 0, 0, 0.60)",
              filter: "blur(62px)",
            }}
          />
        </Box>

        <Box
          sx={{
            position: "fixed", // Position the dialog above the backdrop
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)", // Center the dialog
            zIndex: 6000, // Higher zIndex than the backdrop
          }}
        >
          {/* ------Dialog card------ */}
          <Box
            sx={{
              width: { xs: "90vw", sm: "588px" },
              maxWidth: { xs: "90vw", sm: "588px" },
              padding: { xs: "24px", sm: "40px" },
              height: "auto",
              bgcolor: "#1B1B1B",
              borderRadius: "24px",
              border: "1px solid #3D3D3D",
              cursor: "default",
              position: "relative",
            }}
          >
            <CloseSharp
              onClick={(e) => {
                e.stopPropagation();
                props?.setActiveSwitchPlan(false);
              }}
              sx={{
                position: "absolute",
                right: { xs: "24px", sm: "38px" },
                top: { xs: "24px", sm: "40px" },
                cursor: "pointer",
                color: "white",
              }}
            />
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                gap: "24px",
              }}
            >
              <Typography color={"white"}>
                You will be charged ${data?.update_summary?.result?.amount}
              </Typography>
              <Typography color={"white"}>
                You will be billed for the prorated amount.
              </Typography>

              {/* *****Seperator***** */}
              <Box
                sx={{
                  flex: 1,
                  height: "1px",
                  border: "1px solid rgba(255, 255, 255, 0.1)",
                }}
              />

              {/* ------Action buttons container------ */}
              <Box
                sx={{
                  display: "flex",
                  flexDirection: { xs: "column", sm: "row" },
                  alignItems: "center",
                  gap: "12px",
                  justifyContent: "center",
                }}
              >
                <ButtonPrimary
                  onClick={() => {
                    props?.handleConfirmUpdatePlan();
                  }}
                  sx={{
                    width: { xs: "100%", sm: "174px" },
                  }}
                  label="Confirm Update Subscription"
                />

                <ButtonPrimary
                  onClick={() => {
                    //redirect on new page on new tab
                    window.open(
                      data?.management_urls?.update_payment_method,
                      "_blank"
                    );
                  }}
                  sx={{
                    bgcolor: colors.primaryRed,
                    width: { xs: "100%", sm: "244px" },
                    ":hover": {
                      bgcolor: colors.primaryRed,
                    },
                  }}
                  LabelStyle={{
                    color: "white",
                  }}
                  label="Update Payment Method"
                />
              </Box>
            </Box>
          </Box>
        </Box>
      </>
    )
  );
};

export default ConfirmSwitchPlanDialog;
