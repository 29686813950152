import React from "react";

const CategoryExpandIcon = () => {
  return (
    <svg
      width="32"
      height="32"
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clip-path="url(#clip0_5080_8818)">
        <path
          d="M15.8038 11.0178C15.7417 11.0368 15.6768 11.0498 15.6167 11.0748C15.4537 11.1428 15.4257 11.1818 15.2917 11.2918L7.28775 19.2968C7.20475 19.3978 7.12075 19.5008 7.07075 19.6208C6.89475 20.0448 7.05575 20.5798 7.43875 20.8358C7.76675 21.0548 8.22275 21.0548 8.55075 20.8358C8.60575 20.7998 8.65175 20.7528 8.70275 20.7118L15.9997 13.4148L23.2957 20.7118L23.4477 20.8358C23.5057 20.8668 23.5608 20.9028 23.6208 20.9278C24.0438 21.1038 24.5757 20.9478 24.8357 20.5598C25.0547 20.2318 25.0547 19.7758 24.8357 19.4478C24.7987 19.3938 24.7527 19.3468 24.7107 19.2968L16.7067 11.2918C16.6557 11.2508 16.6097 11.2038 16.5547 11.1678C16.4457 11.0948 16.3237 11.0438 16.1947 11.0178C16.0667 10.9928 15.9347 11.0058 15.8038 11.0178Z"
          fill="white"
        />
      </g>
      <defs>
        <clipPath id="clip0_5080_8818">
          <rect width="32" height="32" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default CategoryExpandIcon;
