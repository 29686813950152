const isActiveLocalHost = false;

export let HOST_NAME: any = "http://localhost:8000";
export let CLOUDFRONT_DOMAIN: string = "https://d2viq8pnr9yec8.cloudfront.net";

const url = window.location.href;
const hostname = new URL(url).hostname;
const parts = hostname.split(".");
const subdomain = parts[0];

//@ts-ignore
if (isActiveLocalHost === false) {
  if (hostname === "www.uiwiki.co" || subdomain === "uiwiki") {
    HOST_NAME = "https://api.uiwiki.co";
  } else {
    HOST_NAME = "https://staging.uiwiki.co/api";
  }
}

export const DAILY_SUBSCRIPTION_PLAN_ID = ""; //For testing
export const MONTHLY_SUBSCRIPTION_PLAN_ID = "pri_01jfhmv92cq2g2f9k5r9v6d8m2"; //10$ MONTHLY PLAN ID
export const YEARLY_SUBSCRIPTION_PLAN_ID = "pri_01jfm0ze893f1zez3nky90kfh4"; //72$ YEARLY PLAN ID
export const LIFETIME_SUBSCRIPTION_PLAN_ID = "pri_01jfm10cfex8ak21r7t9nsk4qa"; //72$ YEARLY PLAN ID
export const PADDLE_CLIENT_TOKEN = "test_2f26563f7976473bdfb32b7159a";
export const PADDLE_ENVIRONMENT = "sandbox";

// container min height
export const SECTION_MIN_HEIGHT = `calc(100vh - 76px)`;

export const selectedRangeStartEndDate = (range: string) => {
  switch (range?.toUpperCase()) {
    case "THIS MONTH":
      return {
        startDate: new Date(new Date().getFullYear(), new Date().getMonth(), 1),
        endDate: new Date(),
      };
    case "LAST MONTH":
      return {
        startDate: new Date(
          new Date().getFullYear(),
          new Date().getMonth() - 1,
          1
        ),
        endDate: new Date(new Date().getFullYear(), new Date().getMonth(), 0),
      };
    case "LAST 3 MONTH":
      return {
        startDate: new Date(
          new Date().getFullYear(),
          new Date().getMonth() - 2,
          1
        ),
        endDate: new Date(new Date().getFullYear(), new Date().getMonth(), 0),
      };
    case "LAST 6 MONTH":
      return {
        startDate: new Date(
          new Date().getFullYear(),
          new Date().getMonth() - 5,
          1
        ),
        endDate: new Date(new Date().getFullYear(), new Date().getMonth(), 0),
      };
    case "LAST 1 YEAR":
      return {
        startDate: new Date(
          new Date().getFullYear() - 1,
          new Date().getMonth() + 1,
          1
        ),
        endDate: new Date(new Date().getFullYear(), new Date().getMonth(), 0),
      };
    case "THIS WEEK": {
      const today = new Date();
      const startOfWeek = today.getDate() - today.getDay() + 1; // Start of current week (Monday)
      const endOfWeek = startOfWeek + 6; // End of current week (Sunday)
      return {
        startDate: new Date(today.setDate(startOfWeek)),
        endDate: new Date(today.setDate(endOfWeek)),
      };
    }
    case "LAST WEEK": {
      const today = new Date();
      const lastWeekStart = today.getDate() - today.getDay() - 7; // Start of last week (Sunday)
      const lastWeekEnd = lastWeekStart + 6; // End of last week (Saturday)
      return {
        startDate: new Date(today.setDate(lastWeekStart)),
        endDate: new Date(today.setDate(lastWeekEnd)),
      };
    }
    case "CUSTOM DATE": {
      const today = new Date();
      const lastWeekStart = today.getDate() - today.getDay() - 7; // Start of last week (Sunday)
      const lastWeekEnd = lastWeekStart + 6; // End of last week (Saturday)
      return {
        startDate: new Date(today.setDate(lastWeekStart)),
        endDate: new Date(today.setDate(lastWeekEnd)),
      };
    }
    case "QUARTER TO DATE": {
      const today = new Date();
      const quarterStartMonth = Math.floor(today.getMonth() / 3) * 3; // Start month of the quarter
      const quarterStartDate = new Date(
        today.getFullYear(),
        quarterStartMonth,
        1
      ); // Start date of the quarter
      return { startDate: quarterStartDate, endDate: today };
    }
    case "YEAR TO DATE": {
      const today = new Date();
      const yearStartDate = new Date(today.getFullYear(), 0, 1); // Start date of the current year (January 1st)
      return { startDate: yearStartDate, endDate: today };
    }
    default:
      break;
  }
};

export const faqs = [
  {
    question: "What is UI Wiki?",
    description:
      "UI Wiki is a design marketplace platform offering a vast library of Figma design components. Subscribers can search, select, and copy high-quality design components from our platform and directly paste them into Figma, saving time and enhancing project workflows.",
  },
  {
    question: "Who can benefit from using UI Wiki?",
    description:
      "UI Wiki is designed for both professional UI/UX designers and non-designers. Designers can quickly find and implement high-quality components, speeding up their workflow. Non-designers can access ready-made design elements, making it easy to incorporate professional designs into their projects.",
  },
  {
    question: "What types of design components does UI Wiki offer?",
    description:
      "Currently, UI Wiki provides website components tailored for various design needs. Soon, we will expand our offerings to include mobile components, logos, branding assets, fonts, and more.",
  },
  {
    question: "How does the subscription model work?",
    description:
      "UI Wiki operates on a subscription basis. By subscribing, you gain unlimited access to all available design components and new assets as they’re added. Simply choose the subscription plan that best fits your needs to get started.",
  },
  {
    question: "How do I use the design components from UI Wiki?",
    description:
      "It’s easy! Once you’ve found a component you like, you can select and copy it directly from our platform and paste it into your Figma workspace. No downloading or exporting is required—just copy and paste.",
  },
  {
    question: "Is there a free trial or beta access for new users?",
    description:
      "Yes! We’re currently in the beta testing stage, and early users may have access to certain features to explore the platform. Check our website or reach out to support for the latest information on beta access.",
  },
  {
    question: "Will more components be added to the platform?",
    description:
      "Absolutely. We plan to keep expanding our library with new website and mobile components, logos, branding elements, fonts, and other valuable resources to support our users’ evolving needs.",
  },
  {
    question: "Can I use UI Wiki components for commercial projects?",
    description:
      "Yes, our components can be used for personal and commercial projects. However, please ensure that you follow any specific licensing guidelines provided with each component.",
  },
  {
    question: "Can non-designers use UI Wiki effectively?",
    description:
      "Definitely. UI Wiki is designed to be user-friendly, with pre-made components that are easy to implement. Whether you’re a seasoned designer or new to the world of design, you’ll find it simple to add professional-quality elements to your projects.",
  },
  {
    question: "Will I need Figma to use UI Wiki?",
    description:
      "Yes, UI Wiki components are built for Figma, so you’ll need a Figma account to use and edit the components. If you don’t have an account, you can create one for free on Figma’s website.",
  },
  {
    question: "How can I provide feedback or request new features?",
    description:
      "We’d love to hear from you! As we’re continually improving UI Wiki, user feedback is invaluable. You can submit feedback or feature requests through our website’s contact page or by reaching out to support.",
  },
  {
    question: "Where can I get support if I need help?",
    description:
      "Our support team is here to assist you with any questions or issues. You can contact us through the support form on our website, and we’ll get back to you as soon as possible.",
  },
  {
    question: "Can I cancel my subscription anytime?",
    description:
      "Yes, you can cancel your subscription at any time through your account settings. You will still have access to UI Wiki until the end of your billing period.",
  },
];

export const handleGoogleSignIn = () => {
  window.location.href = `${HOST_NAME}/auth/google`;
};
