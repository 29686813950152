import {
  Box,
  Tab,
  Tabs,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import React, { useEffect, useRef, useState } from "react";
import { CrownIconSvg } from "../../../../../assets/icons/CrownIconSvg";
import { ButtonPrimary } from "../../../../../shared/components/button/Buttons";
import { TickCircleIconSvg } from "../../../../../assets/icons/TickCircleIconSvg";
import { useGlobalContext } from "../../../../../App";
import {
  MONTHLY_SUBSCRIPTION_PLAN_ID,
  PADDLE_CLIENT_TOKEN,
  PADDLE_ENVIRONMENT,
  YEARLY_SUBSCRIPTION_PLAN_ID,
  LIFETIME_SUBSCRIPTION_PLAN_ID,
} from "../../../../../utils/constants";
import Cookies from "js-cookie";
import { getUserIdFromCookies } from "../../../../../utils/extensions";
import {
  updateSubscription,
  updateSubscriptionPreview,
} from "../../../../../services/user";
import ConfirmSwitchPlanDialog from "./ConfirmSwitchPlanDialog";

type Props = {
  membershipCardsGap?: string;
  activePlan?: string;
  activeSubscriptions?: any;
};

declare global {
  interface Window {
    Paddle?: any;
  }
}

const MembershipPlans = (props: Props) => {
  const {
    userDetails,
    setActiveLoginDialog,
    activeLoginDialog,
    activeSignupDialog,
    activeResetDialog,
  } = useGlobalContext();

  const { activePlan, activeSubscriptions } = props;

  const theme = useTheme();
  const userId = getUserIdFromCookies();
  const breakpoint_down_769 = useMediaQuery(theme.breakpoints.down(769));
  const carouselRef: any = useRef(null);

  const [chooseRegularPlanLoading, setChooseRegularPlanLoading] =
    useState<boolean>(false);
  const [chooseLifetimePlanLoading, setChooseLifetimePlanLoading] =
    useState<boolean>(false);

  const [activeSubscription, setActiveSubscription] = useState<any>({});

  const [activeSwitchPlan, setActiveSwitchPlan] = useState<boolean>(false);
  const [planPreviewData, setPlanPreviewData] = useState<any>({});

  // TabPanel handling states and functions
  const [value, setValue] = useState(0);

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };

  const handleConfirmUpdatePlan = async () => {
    await updateSubscription({
      userId,
      updatedPlanId: planPreviewData?.items[0]?.price?.id,
      currentSubscriptionId: activeSubscription?._id,
      updatedPlanName: planPreviewData?.items[0]?.price?.name,
    })
      .then((res: any) => {
        setTimeout(() => {
          window.location.reload();
        }, 3000);
      })
      .catch((err) => console.log(err));
  };

  const handleShowSubscriptionPreview = async (
    planName: string,
    planId: string
  ) => {
    await updateSubscriptionPreview({
      userId,
      updatedPlanName: planName,
      updatedPlanId: planId,
      currentSubscriptionId: activeSubscription?._id,
    })
      .then((res: any) => {
        setPlanPreviewData(res);
        setActiveSwitchPlan(true);
      })
      .catch((err) => console.log(err));
  };

  // Open Paddle Checkout with the subscription plan
  const openPaddleCheckout = async (planId: string, planName: string) => {
    if (!Cookies.get("jstoken")) {
      setActiveLoginDialog(true);
      return;
    }

    //If the User is switching Plans We have two options
    //Call a preview update api to get the data for the popup
    //1. User Goes from Monthly to Yearly
    //2. User Goes from Yearly to Monthly
    if (activePlan === "MONTHLY" && planName === "YEARLY") {
      handleShowSubscriptionPreview(planName, planId);
      return;
    } else if (activePlan === "YEARLY" && planName === "MONTHLY") {
      handleShowSubscriptionPreview(planName, planId);
      return;
    }

    //@ts-ignore
    if (window.Paddle) {
      //@ts-ignore
      Paddle.Checkout.open({
        items: [
          {
            priceId: planId,
            quantity: 1, // Purchase quantity
          },
        ],
        settings: {
          displayMode: "overlay", // Display as overlay
          theme: "light", // Dark theme
          variant: "one-page", // One-page checkout
          locale: "en", // Force English language
        },
        customData: {
          userId: userId,
          planName: planName,
        },
      });
    }
  };

  useEffect(() => {
    //@ts-ignore
    if (window.Paddle) {
      //@ts-ignore
      Paddle.Environment.set(PADDLE_ENVIRONMENT);
      //@ts-ignore
      Paddle.Initialize({
        token: PADDLE_CLIENT_TOKEN,
        eventCallback: function (data: any) {
          if (data.name == "checkout.completed") {
            //reload the window here
            setTimeout(() => {
              window.location.reload();
            }, 3000);
          }
        },
      });
    }
  }, []);

  useEffect(() => {
    //if activeSubscriptions is an array
    if (Array.isArray(activeSubscriptions)) {
      for (let subscription of activeSubscriptions) {
        if (subscription?.planType === activePlan) {
          setActiveSubscription(subscription);
        }
      }
    }
  }, [activeSubscriptions]);

  let monthlyPlanButtonOpacity: any = "100%";
  let yearlyPlanButtonOpacity: any = "100%";
  let lifetimePlanButtonOpacity: any = "100%";
  let monthlyPlanButtonLabel: any = "Choose Plan";
  let yearlyPlanButtonLabel: any = "Choose Plan";
  let lifetimePlanButtonLabel: any = "Choose Plan";

  if (activePlan === "MONTHLY") {
    monthlyPlanButtonOpacity = "30%";
    monthlyPlanButtonLabel = "Active Plan";
    yearlyPlanButtonOpacity = "100%";
    yearlyPlanButtonLabel = "Switch to Yearly";
    lifetimePlanButtonOpacity = "100%";
    lifetimePlanButtonLabel = "Switch to Lifetime";
  } else if (activePlan === "YEARLY") {
    monthlyPlanButtonOpacity = "100%";
    monthlyPlanButtonLabel = "Switch to Monthly";
    yearlyPlanButtonOpacity = "30%";
    yearlyPlanButtonLabel = "Active Plan";
    lifetimePlanButtonOpacity = "100%";
    lifetimePlanButtonLabel = "Switch to Lifetime";
  } else if (activePlan === "LIFETIME") {
    monthlyPlanButtonOpacity = "30%";
    monthlyPlanButtonLabel = "Choose Plan";
    yearlyPlanButtonOpacity = "30%";
    yearlyPlanButtonLabel = "Choose Plan";
    lifetimePlanButtonOpacity = "30%";
    lifetimePlanButtonLabel = "Active Plan";
  }
  //Stop scrolling when popup is open
  useEffect(() => {
    if (activeLoginDialog || activeSignupDialog || activeResetDialog) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "auto";
    }

    return () => {
      document.body.style.overflow = "auto";
    };
  }, [activeLoginDialog, activeSignupDialog, activeResetDialog]);
  return (
    <Box
      sx={{
        flex: 1,
        display: "flex",
        flexDirection: "column",
        width: "100%",
        alignItems: "center",
        gap: "40px",
      }}
    >
      <Box
        sx={{
          paddingX: { xs: "24px", sm: "40px", md: "80px" },
          width: { xs: "100%", sm: "fit-content" },
          display: "flex",
          justifyContent: "center",
        }}
      >
        {activeSwitchPlan && (
          <ConfirmSwitchPlanDialog
            activeDialog={activeSwitchPlan}
            setActiveSwitchPlan={setActiveSwitchPlan}
            data={planPreviewData}
            handleConfirmUpdatePlan={handleConfirmUpdatePlan}
          />
        )}
        <Tabs
          value={value}
          onChange={handleChange}
          variant="scrollable"
          scrollButtons="auto"
          indicatorColor="primary"
          TabIndicatorProps={{ style: { display: "none" } }}
          TabScrollButtonProps={{
            style: {
              color: "white",
            },
          }}
          visibleScrollbar={true}
          sx={{
            borderColor: "divider",
            fontWeight: "500",
            color: "white",
            bgcolor: "rgba(255, 255, 255, 0.1)",
            padding: "6px",
            borderRadius: "14px",
            flex: 1,
            width: { xs: "100%", sm: "fit-content" },
            minHeight: "fit-content",
            "& .MuiTabs-flexContainer": {
              display: "flex",
              flexDirection: "row",
              gap: "12px",
              padding: "0px",
            },
          }}
          aria-label="scrollable auto tabs example"
        >
          <Tab
            disableRipple
            sx={{
              textTransform: "none",
              fontWeight: "500",
              color: "white",
              padding: "0px !important",
              width: { xs: "58%", sm: "fit-content" },
            }}
            label={
              <Box
                sx={{
                  bgcolor: value === 0 ? "#CCFF00" : "transparent",
                  color: value === 0 ? "black" : "white",
                  borderRadius: "14px",
                  minHeight: "63px !important",
                  maxHeight: "63px !important",
                  width: { xs: "100%", sm: "228px" },
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  mr: "0px",
                  gap: "8px",
                }}
              >
                <Typography fontWeight={600} variant="subtitle1">
                  Annually
                </Typography>

                <Typography
                  sx={{
                    textAlign: "center",
                    width: "93px",
                    height: "33px",
                    borderRadius: "59px",
                    bgcolor: value === 0 ? "#000000" : "#FFFFFF",
                    color: value === 0 ? "#FFFFFF" : "black",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    fontSize: { xs: "12px", sm: "14px" },
                  }}
                  fontWeight={600}
                  variant="body2"
                >
                  Save 40%
                </Typography>
              </Box>
            }
          />
          <Tab
            disableRipple
            sx={{
              textTransform: "none",
              fontWeight: "500",
              color: "white",
              padding: "0px !important",
              width: { xs: "38%", sm: "fit-content" },
            }}
            label={
              <Box
                sx={{
                  bgcolor: value === 1 ? "#CCFF00" : "transparent",
                  color: value === 1 ? "black" : "white",
                  borderRadius: "14px",
                  minHeight: "63px !important",
                  maxHeight: "63px !important",
                  width: { xs: "100%", sm: "228px" },
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  padding: "0px !important",
                }}
              >
                <Typography fontWeight={600} variant="subtitle1">
                  Monthly
                </Typography>
              </Box>
            }
          />
        </Tabs>
      </Box>

      {/* This one is for Annually  */}
      <TabPanel value={value} index={0}>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "flex-start",
            width: "100%",
          }}
        >
          {/* --------pricing cards container-------- */}

          {breakpoint_down_769 ? (
            <Box
              className="HideScrollbar"
              ref={carouselRef}
              sx={{
                display: "flex",
                alignItems: "stretch",
                overflowX: "auto",
                scrollSnapType: "x mandatory",
                gap: props?.membershipCardsGap
                  ? props?.membershipCardsGap
                  : "24px",
                padding: "0px 24px",
                width: "100%",
              }}
            >
              {/* ------Price card 1------ */}
              <Box
                sx={{
                  flex: "1",
                  display: "flex",
                  flexDirection: "column",
                  padding: { xs: "24px", md: "40px" },
                  borderRadius: "14px",
                  bgcolor: "rgba(255, 255, 255, 0.06)",
                  maxWidth: { xs: "321px", md: "428px" },
                  minWidth: { xs: "321px", md: "428px" },
                  gap: "24px",
                }}
              >
                {/* -----Price section----- */}
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    gap: "16px",
                  }}
                >
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      gap: "12px",
                    }}
                  >
                    <Box
                      sx={{
                        width: "48px",
                        height: "48px",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        borderRadius: "50%",
                        bgcolor: "rgba(255, 255, 255, 0.1)",
                      }}
                    >
                      <CrownIconSvg />
                    </Box>
                    <Typography
                      sx={{ fontWeight: "600", color: "white" }}
                      variant="h4"
                    >
                      Regular
                    </Typography>
                  </Box>

                  <Typography
                    sx={{
                      fontSize: "58px",
                      color: "white",
                      fontWeight: "600",
                      display: "flex",
                      alignItems: "center",
                      gap: "12px",
                    }}
                    variant="h1"
                  >
                    $6{" "}
                    <span
                      style={{
                        fontSize: "18px",
                        color: "rgba(255, 255, 255, 0.8)",
                      }}
                    >
                      /month{" "}
                      <span style={{ color: "#767676" }}>*billed yearly</span>
                    </span>
                  </Typography>

                  <ButtonPrimary sx={{ width: "100%" }} label="Get Regular" />
                </Box>

                {/* *****Seperator***** */}
                <Box
                  sx={{
                    // flex: 1,
                    height: "1px",
                    border: "1px solid rgba(255, 255, 255, 0.1)",
                  }}
                />

                {/* ------Pricing Details------ */}
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    gap: "16px",
                  }}
                >
                  <Typography
                    sx={{ fontWeight: "600", color: "white" }}
                    variant="h4"
                  >
                    Benefits
                  </Typography>
                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      gap: "16px",
                    }}
                  >
                    <TickCircleIconSvg />{" "}
                    <Typography
                      sx={{ fontWeight: "400", color: "white" }}
                      variant="subtitle1"
                    >
                      Access to 1000+ premium components
                    </Typography>
                  </Box>
                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      gap: "16px",
                    }}
                  >
                    <TickCircleIconSvg />{" "}
                    <Typography
                      sx={{ fontWeight: "400", color: "white" }}
                      variant="subtitle1"
                    >
                      Unlimited downloads per day
                    </Typography>
                  </Box>
                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      gap: "16px",
                    }}
                  >
                    <TickCircleIconSvg />{" "}
                    <Typography
                      sx={{ fontWeight: "400", color: "white" }}
                      variant="subtitle1"
                    >
                      Weekly components release
                    </Typography>
                  </Box>
                </Box>

                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    gap: "16px",
                  }}
                >
                  <Typography
                    sx={{ fontWeight: "600", color: "white" }}
                    variant="h4"
                  >
                    Additional Benefits
                  </Typography>
                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      gap: "16px",
                    }}
                  >
                    <TickCircleIconSvg />{" "}
                    <Typography
                      sx={{ fontWeight: "400", color: "white" }}
                      variant="subtitle1"
                    >
                      Fast support
                    </Typography>
                  </Box>
                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      gap: "16px",
                    }}
                  >
                    <TickCircleIconSvg />{" "}
                    <Typography
                      sx={{ fontWeight: "400", color: "white" }}
                      variant="subtitle1"
                    >
                      Cancel Anytime: No commitments
                    </Typography>
                  </Box>
                </Box>
              </Box>

              {/* ------Price card 2------ */}
              <Box
                sx={{
                  flex: "1",
                  display: "flex",
                  flexDirection: "column",
                  padding: { xs: "24px", md: "40px" },
                  borderRadius: "14px",
                  bgcolor: "rgba(255, 255, 255, 0.06)",
                  maxWidth: { xs: "321px", md: "428px" },
                  minWidth: { xs: "321px", md: "428px" },
                  gap: "24px",
                }}
              >
                {/* -----Price section----- */}
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    gap: "16px",
                  }}
                >
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      gap: "12px",
                    }}
                  >
                    <Box
                      sx={{
                        width: "48px",
                        height: "48px",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        borderRadius: "50%",
                        bgcolor: "rgba(255, 255, 255, 0.1)",
                      }}
                    >
                      <CrownIconSvg />
                    </Box>
                    <Typography
                      sx={{ fontWeight: "600", color: "white" }}
                      variant="h4"
                    >
                      Lifetime
                    </Typography>
                  </Box>

                  <Typography
                    sx={{
                      fontSize: "58px",
                      color: "white",
                      fontWeight: "600",
                      display: "flex",
                      alignItems: "center",
                      gap: "12px",
                    }}
                    variant="h1"
                  >
                    $250{" "}
                    <span
                      style={{
                        fontSize: "18px",
                        color: "rgba(255, 255, 255, 0.8)",
                      }}
                    >
                      /one-time payment
                    </span>
                  </Typography>

                  <ButtonPrimary sx={{ width: "100%" }} label="Get Lifetime" />
                </Box>

                {/* *****Seperator***** */}
                <Box
                  sx={{
                    // flex: 1,
                    height: "1px",
                    border: "1px solid rgba(255, 255, 255, 0.1)",
                  }}
                />

                {/* ------Pricing Details------ */}
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    gap: "16px",
                  }}
                >
                  <Typography
                    sx={{ fontWeight: "600", color: "white" }}
                    variant="h4"
                  >
                    Benefits
                  </Typography>
                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      gap: "16px",
                    }}
                  >
                    <TickCircleIconSvg />{" "}
                    <Typography
                      sx={{ fontWeight: "400", color: "white" }}
                      variant="subtitle1"
                    >
                      Access to 1000+ premium components
                    </Typography>
                  </Box>
                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      gap: "16px",
                    }}
                  >
                    <TickCircleIconSvg />{" "}
                    <Typography
                      sx={{ fontWeight: "400", color: "white" }}
                      variant="subtitle1"
                    >
                      Unlimited downloads per day
                    </Typography>
                  </Box>
                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      gap: "16px",
                    }}
                  >
                    <TickCircleIconSvg />{" "}
                    <Typography
                      sx={{ fontWeight: "400", color: "white" }}
                      variant="subtitle1"
                    >
                      Daily updates to components
                    </Typography>
                  </Box>
                </Box>

                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    gap: "16px",
                  }}
                >
                  <Typography
                    sx={{ fontWeight: "600", color: "white" }}
                    variant="h4"
                  >
                    Additional Benefits
                  </Typography>
                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      gap: "16px",
                    }}
                  >
                    <TickCircleIconSvg />{" "}
                    <Typography
                      sx={{ fontWeight: "400", color: "white" }}
                      variant="subtitle1"
                    >
                      Lifetime priority support
                    </Typography>
                  </Box>
                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      gap: "16px",
                    }}
                  >
                    <TickCircleIconSvg />{" "}
                    <Typography
                      sx={{ fontWeight: "400", color: "white" }}
                      variant="subtitle1"
                    >
                      Special discount on upcomming features
                    </Typography>
                  </Box>
                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      gap: "16px",
                    }}
                  >
                    <TickCircleIconSvg />{" "}
                    <Typography
                      sx={{ fontWeight: "400", color: "white" }}
                      variant="subtitle1"
                    >
                      Early access to beta tools
                    </Typography>
                  </Box>
                </Box>
              </Box>
            </Box>
          ) : (
            <Box
              sx={{
                display: "flex",
                // alignItems: "flex-start",
                alignItems: "stretch",
                justifyContent: "center",
                width: "100%",
                gap: "36px",
              }}
            >
              {/* ------Price card 1------ */}
              <Box
                sx={{
                  flex: "1",
                  display: "flex",
                  flexDirection: "column",
                  padding: { xs: "24px", md: "40px" },
                  borderRadius: "14px",
                  bgcolor: "rgba(255, 255, 255, 0.06)",
                  maxWidth: { xs: "321px", md: "428px" },
                  minWidth: { xs: "321px", md: "428px" },
                  gap: "24px",
                }}
              >
                {/* -----Price section----- */}
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    gap: "16px",
                  }}
                >
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      gap: "12px",
                    }}
                  >
                    <Box
                      sx={{
                        width: "48px",
                        height: "48px",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        borderRadius: "50%",
                        bgcolor: "rgba(255, 255, 255, 0.1)",
                      }}
                    >
                      <CrownIconSvg />
                    </Box>
                    <Typography
                      sx={{ fontWeight: "600", color: "white" }}
                      variant="h4"
                    >
                      Regular
                    </Typography>
                  </Box>

                  <Typography
                    sx={{
                      fontSize: "58px",
                      color: "white",
                      fontWeight: "600",
                      display: "flex",
                      alignItems: "center",
                      gap: "12px",
                    }}
                    variant="h1"
                  >
                    $6
                    <span
                      style={{
                        fontSize: "18px",
                        color: "rgba(255, 255, 255, 0.8)",
                      }}
                    >
                      /month{" "}
                      <span style={{ color: "#767676" }}>*billed yearly</span>
                    </span>
                  </Typography>

                  <ButtonPrimary
                    isLoading={chooseRegularPlanLoading}
                    sx={{
                      width: "100%",
                      opacity: yearlyPlanButtonOpacity,
                    }}
                    onClick={() => {
                      if (
                        activePlan === "YEARLY" ||
                        activePlan === "LIFETIME"
                      ) {
                        return;
                      }

                      openPaddleCheckout(YEARLY_SUBSCRIPTION_PLAN_ID, "YEARLY");
                    }}
                    label={yearlyPlanButtonLabel}
                  />
                </Box>

                {/* *****Seperator***** */}
                <Box
                  sx={{
                    // flex: 1,
                    height: "1px",
                    border: "1px solid rgba(255, 255, 255, 0.1)",
                  }}
                />

                {/* ------Pricing Details------ */}
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    gap: "16px",
                  }}
                >
                  <Typography
                    sx={{ fontWeight: "600", color: "white" }}
                    variant="h4"
                  >
                    Benefits
                  </Typography>
                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      gap: "16px",
                    }}
                  >
                    <TickCircleIconSvg />{" "}
                    <Typography
                      sx={{ fontWeight: "400", color: "white" }}
                      variant="subtitle1"
                    >
                      Access to 1000+ premium components
                    </Typography>
                  </Box>
                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      gap: "16px",
                    }}
                  >
                    <TickCircleIconSvg />{" "}
                    <Typography
                      sx={{ fontWeight: "400", color: "white" }}
                      variant="subtitle1"
                    >
                      Unlimited downloads per day
                    </Typography>
                  </Box>
                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      gap: "16px",
                    }}
                  >
                    <TickCircleIconSvg />{" "}
                    <Typography
                      sx={{ fontWeight: "400", color: "white" }}
                      variant="subtitle1"
                    >
                      Weekly components release
                    </Typography>
                  </Box>
                </Box>

                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    gap: "16px",
                  }}
                >
                  <Typography
                    sx={{ fontWeight: "600", color: "white" }}
                    variant="h4"
                  >
                    Additional Benefits
                  </Typography>
                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      gap: "16px",
                    }}
                  >
                    <TickCircleIconSvg />{" "}
                    <Typography
                      sx={{ fontWeight: "400", color: "white" }}
                      variant="subtitle1"
                    >
                      Fast support
                    </Typography>
                  </Box>
                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      gap: "16px",
                    }}
                  >
                    <TickCircleIconSvg />{" "}
                    <Typography
                      sx={{ fontWeight: "400", color: "white" }}
                      variant="subtitle1"
                    >
                      Cancel Anytime: No commitments
                    </Typography>
                  </Box>
                </Box>
              </Box>

              {/* ------Price card 2------ */}
              <Box
                sx={{
                  flex: "1",
                  display: "flex",
                  flexDirection: "column",
                  padding: { xs: "24px", md: "40px" },
                  borderRadius: "14px",
                  bgcolor: "rgba(255, 255, 255, 0.06)",
                  maxWidth: { xs: "321px", md: "428px" },
                  minWidth: { xs: "321px", md: "428px" },
                  gap: "24px",
                }}
              >
                {/* -----Price section----- */}
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    gap: "16px",
                  }}
                >
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      gap: "12px",
                    }}
                  >
                    <Box
                      sx={{
                        width: "48px",
                        height: "48px",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        borderRadius: "50%",
                        bgcolor: "rgba(255, 255, 255, 0.1)",
                      }}
                    >
                      <CrownIconSvg />
                    </Box>
                    <Typography
                      sx={{ fontWeight: "600", color: "white" }}
                      variant="h4"
                    >
                      Lifetime
                    </Typography>
                  </Box>

                  <Typography
                    sx={{
                      fontSize: "58px",
                      color: "white",
                      fontWeight: "600",
                      display: "flex",
                      alignItems: "center",
                      gap: "12px",
                    }}
                    variant="h1"
                  >
                    $250{" "}
                    <span
                      style={{
                        fontSize: "18px",
                        color: "rgba(255, 255, 255, 0.8)",
                      }}
                    >
                      /one-time payment
                    </span>
                  </Typography>

                  <ButtonPrimary
                    sx={{
                      width: "100%",
                      opacity: lifetimePlanButtonOpacity,
                    }}
                    label={lifetimePlanButtonLabel}
                    onClick={() => {
                      if (activePlan === "LIFETIME") {
                        return;
                      }
                      openPaddleCheckout(
                        LIFETIME_SUBSCRIPTION_PLAN_ID,
                        "LIFETIME"
                      );
                    }}
                    isLoading={chooseLifetimePlanLoading}
                  />
                </Box>

                {/* *****Seperator***** */}
                <Box
                  sx={{
                    // flex: 1,
                    height: "1px",
                    border: "1px solid rgba(255, 255, 255, 0.1)",
                  }}
                />

                {/* ------Pricing Details------ */}
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    gap: "16px",
                  }}
                >
                  <Typography
                    sx={{ fontWeight: "600", color: "white" }}
                    variant="h4"
                  >
                    Benefits
                  </Typography>
                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      gap: "16px",
                    }}
                  >
                    <TickCircleIconSvg />{" "}
                    <Typography
                      sx={{ fontWeight: "400", color: "white" }}
                      variant="subtitle1"
                    >
                      Access to 1000+ premium components
                    </Typography>
                  </Box>
                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      gap: "16px",
                    }}
                  >
                    <TickCircleIconSvg />{" "}
                    <Typography
                      sx={{ fontWeight: "400", color: "white" }}
                      variant="subtitle1"
                    >
                      Unlimited downloads per day
                    </Typography>
                  </Box>
                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      gap: "16px",
                    }}
                  >
                    <TickCircleIconSvg />{" "}
                    <Typography
                      sx={{ fontWeight: "400", color: "white" }}
                      variant="subtitle1"
                    >
                      Daily updates to components
                    </Typography>
                  </Box>
                </Box>

                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    gap: "16px",
                  }}
                >
                  <Typography
                    sx={{ fontWeight: "600", color: "white" }}
                    variant="h4"
                  >
                    Additional Benefits
                  </Typography>
                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      gap: "16px",
                    }}
                  >
                    <TickCircleIconSvg />{" "}
                    <Typography
                      sx={{ fontWeight: "400", color: "white" }}
                      variant="subtitle1"
                    >
                      Lifetime priority support
                    </Typography>
                  </Box>
                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      gap: "16px",
                    }}
                  >
                    <TickCircleIconSvg />{" "}
                    <Typography
                      sx={{ fontWeight: "400", color: "white" }}
                      variant="subtitle1"
                    >
                      Special discount on upcomming features
                    </Typography>
                  </Box>
                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      gap: "16px",
                    }}
                  >
                    <TickCircleIconSvg />{" "}
                    <Typography
                      sx={{ fontWeight: "400", color: "white" }}
                      variant="subtitle1"
                    >
                      Early access to beta tools
                    </Typography>
                  </Box>
                </Box>
              </Box>
            </Box>
          )}
        </Box>
      </TabPanel>
      {/* This one is for Monthly  */}
      <TabPanel value={value} index={1}>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "flex-start",
            justifyContent: "flex-start",
            width: "100%",
          }}
        >
          {/* --------pricing cards container-------- */}
          {breakpoint_down_769 ? (
            <Box
              className="HideScrollbar"
              ref={carouselRef}
              sx={{
                display: "flex",
                alignItems: "stretch",
                overflowX: "auto",
                scrollSnapType: "x mandatory",
                gap: props?.membershipCardsGap
                  ? props?.membershipCardsGap
                  : "24px",
                padding: "0px 24px",
                width: "100%",
              }}
            >
              {/* ------Price card 1------ */}
              <Box
                sx={{
                  display: "flex",
                  flex: "1",
                  flexDirection: "column",
                  padding: { xs: "24px", md: "40px" },
                  borderRadius: "14px",
                  bgcolor: "rgba(255, 255, 255, 0.06)",
                  maxWidth: { xs: "321px", md: "428px" },
                  minWidth: { xs: "321px", md: "428px" },
                  gap: "24px",
                }}
              >
                {/* -----Price section----- */}
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    gap: "16px",
                  }}
                >
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      gap: "12px",
                    }}
                  >
                    <Box
                      sx={{
                        width: "48px",
                        height: "48px",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        borderRadius: "50%",
                        bgcolor: "rgba(255, 255, 255, 0.1)",
                      }}
                    >
                      <CrownIconSvg />
                    </Box>
                    <Typography
                      sx={{ fontWeight: "600", color: "white" }}
                      variant="h4"
                    >
                      Regular
                    </Typography>
                  </Box>

                  <Typography
                    sx={{
                      fontSize: "58px",
                      color: "white",
                      fontWeight: "600",
                      display: "flex",
                      alignItems: "center",
                      gap: "12px",
                    }}
                    variant="h1"
                  >
                    $10
                    <span
                      style={{
                        fontSize: "18px",
                        color: "rgba(255, 255, 255, 0.8)",
                      }}
                    >
                      /month
                    </span>
                  </Typography>

                  <ButtonPrimary
                    isLoading={chooseRegularPlanLoading}
                    onClick={() => {
                      if (
                        activePlan === "MONTHLY" ||
                        activePlan === "LIFETIME"
                      ) {
                        return;
                      }

                      openPaddleCheckout(
                        MONTHLY_SUBSCRIPTION_PLAN_ID,
                        "MONTHLY"
                      );
                    }}
                    sx={{ width: "100%", opacity: monthlyPlanButtonOpacity }}
                    label={monthlyPlanButtonLabel}
                  />
                </Box>

                {/* *****Seperator***** */}
                <Box
                  sx={{
                    // flex: 1,
                    height: "1px",
                    border: "1px solid rgba(255, 255, 255, 0.1)",
                  }}
                />

                {/* ------Pricing Details------ */}
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    gap: "16px",
                  }}
                >
                  <Typography
                    sx={{ fontWeight: "600", color: "white" }}
                    variant="h4"
                  >
                    Benefits
                  </Typography>
                  <Box
                    sx={{ display: "flex", alignItems: "center", gap: "16px" }}
                  >
                    <TickCircleIconSvg />
                    <Typography
                      sx={{ fontWeight: "400", color: "white" }}
                      variant="subtitle1"
                    >
                      Access to 1000+ premium components
                    </Typography>
                  </Box>
                  <Box
                    sx={{ display: "flex", alignItems: "center", gap: "16px" }}
                  >
                    <TickCircleIconSvg />
                    <Typography
                      sx={{ fontWeight: "400", color: "white" }}
                      variant="subtitle1"
                    >
                      Unlimited usage without restrictions
                    </Typography>
                  </Box>
                  <Box
                    sx={{ display: "flex", alignItems: "center", gap: "16px" }}
                  >
                    <TickCircleIconSvg />
                    <Typography
                      sx={{ fontWeight: "400", color: "white" }}
                      variant="subtitle1"
                    >
                      Weekly components release
                    </Typography>
                  </Box>
                </Box>

                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    gap: "16px",
                  }}
                >
                  <Typography
                    sx={{ fontWeight: "600", color: "white" }}
                    variant="h4"
                  >
                    Additional Benefits
                  </Typography>
                  <Box
                    sx={{ display: "flex", alignItems: "center", gap: "16px" }}
                  >
                    <TickCircleIconSvg />{" "}
                    <Typography
                      sx={{ fontWeight: "400", color: "white" }}
                      variant="subtitle1"
                    >
                      Fast support
                    </Typography>
                  </Box>
                  <Box
                    sx={{ display: "flex", alignItems: "center", gap: "16px" }}
                  >
                    <TickCircleIconSvg />{" "}
                    <Typography
                      sx={{ fontWeight: "400", color: "white" }}
                      variant="subtitle1"
                    >
                      Cancel Anytime: No commitments
                    </Typography>
                  </Box>
                </Box>
              </Box>

              {/* ------Price card 2------ */}
              <Box
                sx={{
                  display: "flex",
                  flex: "1",
                  flexDirection: "column",
                  padding: { xs: "24px", md: "40px" },
                  borderRadius: "14px",
                  bgcolor: "rgba(255, 255, 255, 0.06)",
                  maxWidth: { xs: "321px", md: "428px" },
                  minWidth: { xs: "321px", md: "428px" },
                  gap: "24px",
                }}
              >
                {/* -----Price section----- */}
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    gap: "16px",
                  }}
                >
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      gap: "12px",
                    }}
                  >
                    <Box
                      sx={{
                        width: "48px",
                        height: "48px",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        borderRadius: "50%",
                        bgcolor: "rgba(255, 255, 255, 0.1)",
                      }}
                    >
                      <CrownIconSvg />
                    </Box>
                    <Typography
                      sx={{ fontWeight: "600", color: "white" }}
                      variant="h4"
                    >
                      Lifetime
                    </Typography>
                  </Box>

                  <Typography
                    sx={{
                      fontSize: "58px",
                      color: "white",
                      fontWeight: "600",
                      display: "flex",
                      alignItems: "center",
                      gap: "12px",
                    }}
                    variant="h1"
                  >
                    $250
                    <span
                      style={{
                        fontSize: "18px",
                        color: "rgba(255, 255, 255, 0.8)",
                      }}
                    >
                      /one-time payment
                    </span>
                  </Typography>

                  <ButtonPrimary sx={{ width: "100%" }} label="Get Lifetime" />
                </Box>

                {/* *****Seperator***** */}
                <Box
                  sx={{
                    // flex: 1,
                    height: "1px",
                    border: "1px solid rgba(255, 255, 255, 0.1)",
                  }}
                />

                {/* ------Pricing Details------ */}
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    gap: "16px",
                  }}
                >
                  <Typography
                    sx={{ fontWeight: "600", color: "white" }}
                    variant="h4"
                  >
                    Benefits
                  </Typography>
                  <Box
                    sx={{ display: "flex", alignItems: "center", gap: "16px" }}
                  >
                    <TickCircleIconSvg />{" "}
                    <Typography
                      sx={{ fontWeight: "400", color: "white" }}
                      variant="subtitle1"
                    >
                      Access to 1000+ premium components
                    </Typography>
                  </Box>
                  <Box
                    sx={{ display: "flex", alignItems: "center", gap: "16px" }}
                  >
                    <TickCircleIconSvg />{" "}
                    <Typography
                      sx={{ fontWeight: "400", color: "white" }}
                      variant="subtitle1"
                    >
                      Unlimited usage without restrictions
                    </Typography>
                  </Box>
                  <Box
                    sx={{ display: "flex", alignItems: "center", gap: "16px" }}
                  >
                    <TickCircleIconSvg />{" "}
                    <Typography
                      sx={{ fontWeight: "400", color: "white" }}
                      variant="subtitle1"
                    >
                      Weekly components release
                    </Typography>
                  </Box>
                </Box>

                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    gap: "16px",
                  }}
                >
                  <Typography
                    sx={{ fontWeight: "600", color: "white" }}
                    variant="h4"
                  >
                    Additional Benefits
                  </Typography>
                  <Box
                    sx={{ display: "flex", alignItems: "center", gap: "16px" }}
                  >
                    <TickCircleIconSvg />{" "}
                    <Typography
                      sx={{ fontWeight: "400", color: "white" }}
                      variant="subtitle1"
                    >
                      Lifetime priority support
                    </Typography>
                  </Box>
                  <Box
                    sx={{ display: "flex", alignItems: "center", gap: "16px" }}
                  >
                    <TickCircleIconSvg />{" "}
                    <Typography
                      sx={{ fontWeight: "400", color: "white" }}
                      variant="subtitle1"
                    >
                      Special descount on upcomming features
                    </Typography>
                  </Box>
                  <Box
                    sx={{ display: "flex", alignItems: "center", gap: "16px" }}
                  >
                    <TickCircleIconSvg />{" "}
                    <Typography
                      sx={{ fontWeight: "400", color: "white" }}
                      variant="subtitle1"
                    >
                      Early access to beta tools
                    </Typography>
                  </Box>
                </Box>
              </Box>
            </Box>
          ) : (
            <Box
              sx={{
                display: "flex",
                // alignItems: "center",
                alignItems: "stretch",
                justifyContent: "center",
                width: "100%",
                gap: "36px",
              }}
            >
              {/* ------Price card 1------ */}
              <Box
                sx={{
                  display: "flex",
                  flex: "1",
                  flexDirection: "column",
                  padding: { xs: "24px", md: "40px" },
                  borderRadius: "14px",
                  bgcolor: "rgba(255, 255, 255, 0.06)",
                  maxWidth: { xs: "321px", md: "428px" },
                  minWidth: { xs: "321px", md: "428px" },
                  gap: "24px",
                }}
              >
                {/* -----Price section----- */}
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    gap: "16px",
                  }}
                >
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      gap: "12px",
                    }}
                  >
                    <Box
                      sx={{
                        width: "48px",
                        height: "48px",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        borderRadius: "50%",
                        bgcolor: "rgba(255, 255, 255, 0.1)",
                      }}
                    >
                      <CrownIconSvg />
                    </Box>
                    <Typography
                      sx={{ fontWeight: "600", color: "white" }}
                      variant="h4"
                    >
                      Regular
                    </Typography>
                  </Box>

                  <Typography
                    sx={{
                      fontSize: "58px",
                      color: "white",
                      fontWeight: "600",
                      display: "flex",
                      alignItems: "center",
                      gap: "12px",
                    }}
                    variant="h1"
                  >
                    $10
                    <span
                      style={{
                        fontSize: "18px",
                        color: "rgba(255, 255, 255, 0.8)",
                      }}
                    >
                      /month
                    </span>
                  </Typography>

                  <ButtonPrimary
                    isLoading={chooseRegularPlanLoading}
                    onClick={() => {
                      if (
                        activePlan === "MONTHLY" ||
                        activePlan === "LIFETIME"
                      ) {
                        return;
                      }
                      openPaddleCheckout(
                        MONTHLY_SUBSCRIPTION_PLAN_ID,
                        "MONTHLY"
                      );
                    }}
                    sx={{
                      width: "100%",
                      opacity: monthlyPlanButtonOpacity,
                    }}
                    label={monthlyPlanButtonLabel}
                  />
                </Box>

                {/* *****Seperator***** */}
                <Box
                  sx={{
                    // flex: 1,
                    height: "1px",
                    border: "1px solid rgba(255, 255, 255, 0.1)",
                  }}
                />

                {/* ------Pricing Details------ */}
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    gap: "16px",
                  }}
                >
                  <Typography
                    sx={{ fontWeight: "600", color: "white" }}
                    variant="h4"
                  >
                    Benefits
                  </Typography>
                  <Box
                    sx={{ display: "flex", alignItems: "center", gap: "16px" }}
                  >
                    <TickCircleIconSvg />
                    <Typography
                      sx={{ fontWeight: "400", color: "white" }}
                      variant="subtitle1"
                    >
                      Access to 1000+ premium components
                    </Typography>
                  </Box>
                  <Box
                    sx={{ display: "flex", alignItems: "center", gap: "16px" }}
                  >
                    <TickCircleIconSvg />
                    <Typography
                      sx={{ fontWeight: "400", color: "white" }}
                      variant="subtitle1"
                    >
                      Unlimited usage without restrictions
                    </Typography>
                  </Box>
                  <Box
                    sx={{ display: "flex", alignItems: "center", gap: "16px" }}
                  >
                    <TickCircleIconSvg />
                    <Typography
                      sx={{ fontWeight: "400", color: "white" }}
                      variant="subtitle1"
                    >
                      Weekly components release
                    </Typography>
                  </Box>
                </Box>

                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    gap: "16px",
                  }}
                >
                  <Typography
                    sx={{ fontWeight: "600", color: "white" }}
                    variant="h4"
                  >
                    Additional Benefits
                  </Typography>
                  <Box
                    sx={{ display: "flex", alignItems: "center", gap: "16px" }}
                  >
                    <TickCircleIconSvg />{" "}
                    <Typography
                      sx={{ fontWeight: "400", color: "white" }}
                      variant="subtitle1"
                    >
                      Fast support
                    </Typography>
                  </Box>
                  <Box
                    sx={{ display: "flex", alignItems: "center", gap: "16px" }}
                  >
                    <TickCircleIconSvg />{" "}
                    <Typography
                      sx={{ fontWeight: "400", color: "white" }}
                      variant="subtitle1"
                    >
                      Cancel Anytime: No commitments
                    </Typography>
                  </Box>
                </Box>
              </Box>

              {/* ------Price card 2------ */}
              <Box
                sx={{
                  display: "flex",
                  flex: "1",
                  flexDirection: "column",
                  padding: { xs: "24px", md: "40px" },
                  borderRadius: "14px",
                  bgcolor: "rgba(255, 255, 255, 0.06)",
                  maxWidth: { xs: "321px", md: "428px" },
                  minWidth: { xs: "321px", md: "428px" },
                  gap: "24px",
                }}
              >
                {/* -----Price section----- */}
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    gap: "16px",
                  }}
                >
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      gap: "12px",
                    }}
                  >
                    <Box
                      sx={{
                        width: "48px",
                        height: "48px",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        borderRadius: "50%",
                        bgcolor: "rgba(255, 255, 255, 0.1)",
                      }}
                    >
                      <CrownIconSvg />
                    </Box>
                    <Typography
                      sx={{ fontWeight: "600", color: "white" }}
                      variant="h4"
                    >
                      Lifetime
                    </Typography>
                  </Box>

                  <Typography
                    sx={{
                      fontSize: "58px",
                      color: "white",
                      fontWeight: "600",
                      display: "flex",
                      alignItems: "center",
                      gap: "12px",
                    }}
                    variant="h1"
                  >
                    $250
                    <span
                      style={{
                        fontSize: "18px",
                        color: "rgba(255, 255, 255, 0.8)",
                      }}
                    >
                      /one-time payment
                    </span>
                  </Typography>

                  <ButtonPrimary
                    sx={{
                      width: "100%",
                      opacity: lifetimePlanButtonOpacity,
                    }}
                    label={lifetimePlanButtonLabel}
                    onClick={() => {
                      if (activePlan === "LIFETIME") {
                        return;
                      }
                      openPaddleCheckout(
                        LIFETIME_SUBSCRIPTION_PLAN_ID,
                        "LIFETIME"
                      );
                    }}
                    isLoading={chooseLifetimePlanLoading}
                  />
                </Box>

                {/* *****Seperator***** */}
                <Box
                  sx={{
                    // flex: 1,
                    height: "1px",
                    border: "1px solid rgba(255, 255, 255, 0.1)",
                  }}
                />

                {/* ------Pricing Details------ */}
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    gap: "16px",
                  }}
                >
                  <Typography
                    sx={{ fontWeight: "600", color: "white" }}
                    variant="h4"
                  >
                    Benefits
                  </Typography>
                  <Box
                    sx={{ display: "flex", alignItems: "center", gap: "16px" }}
                  >
                    <TickCircleIconSvg />{" "}
                    <Typography
                      sx={{ fontWeight: "400", color: "white" }}
                      variant="subtitle1"
                    >
                      Access to 1000+ premium components
                    </Typography>
                  </Box>
                  <Box
                    sx={{ display: "flex", alignItems: "center", gap: "16px" }}
                  >
                    <TickCircleIconSvg />{" "}
                    <Typography
                      sx={{ fontWeight: "400", color: "white" }}
                      variant="subtitle1"
                    >
                      Unlimited usage without restrictions
                    </Typography>
                  </Box>
                  <Box
                    sx={{ display: "flex", alignItems: "center", gap: "16px" }}
                  >
                    <TickCircleIconSvg />{" "}
                    <Typography
                      sx={{ fontWeight: "400", color: "white" }}
                      variant="subtitle1"
                    >
                      Weekly components release
                    </Typography>
                  </Box>
                </Box>

                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    gap: "16px",
                  }}
                >
                  <Typography
                    sx={{ fontWeight: "600", color: "white" }}
                    variant="h4"
                  >
                    Additional Benefits
                  </Typography>
                  <Box
                    sx={{ display: "flex", alignItems: "center", gap: "16px" }}
                  >
                    <TickCircleIconSvg />{" "}
                    <Typography
                      sx={{ fontWeight: "400", color: "white" }}
                      variant="subtitle1"
                    >
                      Lifetime priority support
                    </Typography>
                  </Box>
                  <Box
                    sx={{ display: "flex", alignItems: "center", gap: "16px" }}
                  >
                    <TickCircleIconSvg />{" "}
                    <Typography
                      sx={{ fontWeight: "400", color: "white" }}
                      variant="subtitle1"
                    >
                      Special discount on upcomming features
                    </Typography>
                  </Box>
                  <Box
                    sx={{ display: "flex", alignItems: "center", gap: "16px" }}
                  >
                    <TickCircleIconSvg />{" "}
                    <Typography
                      sx={{ fontWeight: "400", color: "white" }}
                      variant="subtitle1"
                    >
                      Early access to beta tools
                    </Typography>
                  </Box>
                </Box>
              </Box>
            </Box>
          )}
        </Box>
      </TabPanel>
    </Box>
  );
};

export default MembershipPlans;

export interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

export function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <Box
      sx={{ width: "100%" }}
      className="HideScrollbar"
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ flex: 1 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </Box>
  );
}
