import {
  Box,
  CircularProgress,
  Typography,
  useMediaQuery,
} from "@mui/material";
import { useTheme } from "@mui/material/styles";
import React, { useEffect, useRef, useState } from "react";
import { ImageContainer } from "../../shared/components/ImageContainer";
import {
  HeaderImgage1,
  HeaderImgage2,
  HomeCoverBg,
} from "../../assets/images/image";
import { WikiLogo } from "../../assets/icons/icons";
import NewsletterSection from "../../main/NewsletterSection";
import Search from "../../shared/components/Search";
import Chip from "../../shared/components/Chip";
import ComponentCard from "./components/ComponentCard";
import { ButtonPrimary } from "../../shared/components/button/Buttons";
import { useLocation, useNavigate } from "react-router-dom";
import { useGlobalContext } from "../../App";
import { colors } from "../../utils/colors";
import { getUserById } from "../../services/user";
import Seperator2 from "../../shared/components/Seperator2";
import { getAllComponents } from "../../services/component";
import HomeCongratulationPopUp from "./HomeCongratulationPopUp";
import ProjectComponentDetailScreenPopUp from "../projectComponent/ProjectComponentDetailScreenPopUp";

type Props = {};

const Home = (props: Props) => {
  const carouselRef: any = useRef(null);
  const theme = useTheme();
  const navigate = useNavigate();
  const location = useLocation();
  const [previousUrl, setPreviousUrl] = useState(location.pathname);

  const {
    userDetails,
    activeLoginDialog,
    activeSignupDialog,
    activeResetDialog,
  } = useGlobalContext();
  const { searchProductBy, setSearchProductBy } = useGlobalContext();
  const [activeCongratulationPopup, setActiveCongratulationPopup] =
    useState(false);

  const breakpoint_up_425 = useMediaQuery(theme.breakpoints.up(426));

  const [loading, setLoading] = useState<boolean>(true);
  const [components, setComponents] = useState<any>();
  const [userSubscriptions, setUserSubscriptions] = useState([]);
  const [isSubscribed, setIsSubscribed] = useState(false);

  const handleSearch = (searchBy?: string) => {
    setSearchProductBy(searchBy || "");
    navigateToSearch();
  };

  const handleKeyPress = (e: any) => {
    if (e.key === "Enter") {
      navigateToSearch();
    }
  };

  const handleTagClick = (tag: any) => {
    setSearchProductBy(tag || "");
    navigateToSearch();
  };

  const navigateToSearch = (tag?: any) => {
    navigate("/search", {
      state: { tag: tag },
    });
  };

  const fetchAllComponents = async () => {
    let list: any = localStorage.getItem("componentsList");
    list = JSON.parse(list);
    const paginatedComponents = list?.paginatedComponents || [];

    if (paginatedComponents?.length > 0) {
      const sortedList = paginatedComponents.sort((a: any, b: any) => {
        return (
          new Date(b.updatedAt).getTime() - new Date(a.updatedAt).getTime()
        );
      });
      setComponents(sortedList);
    } else {
      await getAllComponents({ page: 1, pageSize: 24 })
        .then((res: any) => {
          setComponents(res?.paginatedComponents);
        })
        .catch((err: any) => {
          console.log("err", err);
        });
    }
    setLoading(false);
  };

  const [componentDetailsPopup, setComponentDetailsPopup] =
    useState<boolean>(false);
  const [selectedComponent, setSelectedComponent] = useState(null);

  const handleOpenPopup = (component: any) => {
    setSelectedComponent(component);
    setComponentDetailsPopup(true);

    // Update the URL without navigating
    const newUrl = `/component/${component.blurbUrl}`;
    window.history.replaceState(null, "", newUrl);
  };

  useEffect(() => {
    fetchAllComponents();
  }, []);

  useEffect(() => {}, [searchProductBy]);

  const getUserSubscriptionsDetails = async (id: string) => {
    const response = await getUserById(id);
    const subscriptions = response?.data?.subscriptions;

    if (subscriptions?.length > 0) {
      setUserSubscriptions(subscriptions);
      for (let subscription of subscriptions) {
        if (subscription.status === "ACTIVE") {
          setIsSubscribed(true);
        }
      }
    }
  };

  useEffect(() => {
    if (userDetails) {
      getUserSubscriptionsDetails(userDetails._id);
    }
  }, [userDetails]);

  //Not showing user that dialog now
  // useEffect(() => {
  //   const isFirstTimeUser = localStorage.getItem("isFirstTimeUser");

  //   if (isFirstTimeUser === "true") {
  //     setActiveCongratulationPopup(true); // Show the popup
  //     localStorage.removeItem("isFirstTimeUser"); // Remove the flag
  //   }
  // }, []);

  //Stop scrolling when popup is open
  useEffect(() => {
    if (
      componentDetailsPopup ||
      activeCongratulationPopup ||
      activeLoginDialog ||
      activeSignupDialog ||
      activeResetDialog
    ) {
      // Disable scrolling
      document.body.style.overflow = "hidden";
    } else {
      // Enable scrolling
      document.body.style.overflow = "auto";
    }

    // Cleanup on component unmount
    return () => {
      document.body.style.overflow = "auto";
    };
  }, [
    componentDetailsPopup,
    activeCongratulationPopup,
    activeLoginDialog,
    activeSignupDialog,
    activeResetDialog,
  ]);

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        paddingTop: { xs: "24px", md: "80px" },
        paddingBottom: { xs: "57px", md: "80px" },
        // paddingX: { xs: "24px", md: "40px", lg: "80px" },
        height: "100%",
        position: "relative",
        gap: { xs: "48px", md: "80px" },
      }}
    >
      {/* header Section */}
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          gap: { xs: "24px", md: "42px" },
          textAlign: "center",
          flexDirection: "column",
          width: "100%",
        }}
      >
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            gap: "24px",
            textAlign: "center",
            flexDirection: "column",
            width: "100%",
            paddingX: { xs: "24px", md: "40px", lg: "80px" },
          }}
        >
          {breakpoint_up_425 ? (
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                position: "relative",
                maxWidth: "795px",
                width: "100%",
                paddingBottom: "26px",
              }}
            >
              <ImageContainer
                style={{
                  width: "202px",
                  height: "86px",
                  position: "absolute",
                  left: "0px",
                  bottom: "-26px",
                }}
                title="image"
              >
                {HeaderImgage1}
              </ImageContainer>

              <Box
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  borderRadius: "8px",
                  padding: "8px",
                  boxShadow: "inset 0px 0px 0px 2px #3D3D3D",
                }}
              >
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    borderRadius: "8px",
                    padding: "16px",
                    bgcolor: "#292929",
                  }}
                >
                  <ImageContainer
                    style={{ width: "110px", height: "110px" }}
                    height="110px"
                    title="Main Logo"
                  >
                    {WikiLogo}
                  </ImageContainer>
                </Box>
              </Box>

              <ImageContainer
                style={{
                  width: "136px",
                  height: "86px",
                  position: "absolute",
                  aspectRatio: "136px/66px",
                  right: 0,
                  bottom: 14,
                }}
                title="image"
              >
                {HeaderImgage2}
              </ImageContainer>
            </Box>
          ) : (
            <></>
          )}

          <Typography
            sx={{
              fontSize: "58px",
              fontWeight: "600",
              color: "text.secondary",
              background:
                "linear-gradient(81deg, rgba(255,255,255,1) 0%, rgba(48,48,48,1) 100%)",
              WebkitBackgroundClip: "text",
              WebkitTextFillColor: "transparent",
              // maxWidth: "35%",
            }}
            variant="h1"
          >
            Design Smarter and
            <br /> Build Faster
          </Typography>
        </Box>

        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            gap: { xs: "16px", md: "24px" },
            width: { xs: "100%", sm: "auto" },
          }}
        >
          <Box
            sx={{
              maxWidth: { xs: "100%", md: "536px" },
              minWidth: { xs: "100%", md: "100%" },
              width: "100%",
              height: "52px",
              paddingX: { xs: "24px", sm: "0px" },
            }}
          >
            <Search
              style={{
                backgroundColor: colors.primaryGrey,
                width: "100%",
                border: "none",
                height: "100%",
                boxShadow: "inset 0px 0px 0px 1px #3D3D3D",
                borderRadius: "12px",
                padding: "0px 20px 0px 15px",
                outline: "none",
                color: "white",
                fontSize: "18px",
                fontWeight: "400",
              }}
              placeholder="Search"
              onChange={(e: any) => {
                handleSearch(e?.target?.value);
              }}
              onKeyDown={handleKeyPress}
            />
          </Box>

          <Box
            className="HideScrollbar"
            ref={carouselRef}
            sx={{
              display: "flex",
              flexWrap: { xs: "nowrap", sm: "wrap" },
              justifyContent: "center",
              maxWidth: { xs: "100%", sm: "530px" },
              minWidth: { xs: "100% !important", sm: "100%" },
              width: "100%",
              gap: "8px",
              // overflowX: "scroll",
              overflowX: "auto",
              scrollSnapType: "x mandatory",
              paddingX: { xs: "24px", sm: "0px" },
              // scrollPaddingLeft: "80px !important",
            }}
          >
            {[
              "Components",
              "Header",
              "Features",
              "Navigation",
              "FAQ",
              "Testimonials",
              "Image Grid",
              "Content",
              "How It Works",
              "Team",
              "Profile",
            ].map((tag) => (
              <Box
                sx={{
                  flex: "0 0 auto",
                  scrollSnapAlign: "start",
                  minWidth: "fit-content",
                  width: "fit-content",
                  // paddingX: "8px",
                }}
              >
                <Chip
                  key={tag}
                  sx={{ cursor: "pointer" }}
                  title={tag}
                  onClick={() => handleTagClick(tag)}
                />
              </Box>
            ))}
          </Box>
        </Box>
      </Box>

      {/* --------Seperator -------- */}
      <Box sx={{ paddingX: { xs: "24px", md: "40px", lg: "80px" } }}>
        <Seperator2 />
      </Box>

      {/* Content Section */}

      {activeCongratulationPopup && (
        <HomeCongratulationPopUp
          activeDialog={activeCongratulationPopup}
          setActiveDialog={setActiveCongratulationPopup}
        />
      )}

      {/* componentDetailsPopup when user click any perticular component  */}
      {componentDetailsPopup && (
        <ProjectComponentDetailScreenPopUp
          component={selectedComponent}
          onClose={() => {
            window.history.replaceState(null, "", "/");
            setComponentDetailsPopup(false);
          }}
        />
      )}

      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          paddingTop: { xs: "0px", md: "7px" },
          paddingX: { xs: "24px", md: "40px", lg: "80px" },
          // paddingY: { xs: "48px", lg: "80px" },
          alignItems: "center",
          flex: 1,
        }}
      >
        {loading ? (
          <Box
            sx={{
              minWidth: "100%",
              width: "100%",
              minHeight: "300px",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <CircularProgress
              size={24}
              sx={{
                color: "white",
                marginRight: "12px",
              }}
            />
          </Box>
        ) : (
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              flex: 1,
              width: "100%",
              gap: "40px",
            }}
          >
            <Typography variant="h3" color={"white"}>
              Latest Components
            </Typography>

            <Box
              sx={{
                display: "grid",
                width: "100%",
                boxSizing: "border-box",
                gridGap: "16px",
                gridTemplateColumns: {
                  xs: "repeat(auto-fit, minmax(270px, 1fr))",
                  sm: "repeat(2, 1fr)",
                  md: "repeat(auto-fit, minmax(360px, 1fr))",
                }, // Adjust card size and fit into available space
                "@media screen and (max-width: 579px)": {
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  flexWrap: "wrap",
                },
              }}
            >
              {components?.map((data: any, index: number) => {
                return (
                  <Box
                    key={index}
                    sx={{
                      maxWidth: "100%",
                      minWidth: "100%",
                    }}
                  >
                    <ComponentCard
                      data={data}
                      isSubscribed={isSubscribed}
                      onEyeIconClick={() => handleOpenPopup(data)}
                    />
                  </Box>
                );
              })}
            </Box>

            <ButtonPrimary
              onClick={() => {
                navigate("/search");
              }}
              sx={{ width: "150px", height: "54px", mt: "48px" }}
              label="View More"
            />
          </Box>
        )}
      </Box>

      {/* --------Seperator -------- */}
      <Box sx={{ paddingX: { xs: "24px", md: "40px", lg: "80px" } }}>
        <Seperator2 />
      </Box>

      <Box sx={{ paddingX: { xs: "24px", sm: "24px", lg: "80px" } }}>
        <NewsletterSection />
      </Box>
    </Box>
  );
};

export default Home;
