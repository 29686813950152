import React, { useEffect, useState } from "react";
import { Box, Button, Typography } from "@mui/material";
import { CloseSharp } from "@mui/icons-material";
import TextInput from "../shared/components/TextInput";
import LockIcon from "../assets/icons/LockIcon/LockIcon";
import { useNavigate } from "react-router-dom";
import useEnterKeyPress from "../utils/useEnterKeyPress";
import { requestResetPassword } from "../services/authentication";
import { toastError } from "../utils/ToastMessage";
import ResetPasswordRecoveryMailSentPopup from "./ResetPasswordRecoveryMailSentPopup";
import { useGlobalContext } from "../App";
import { isValidEmail } from "../utils/extensions";

type Props = {
  activeDialog: any;
  handleDialog?: any;
};

const ResetPasswordDialog: React.FC<Props> = (props) => {
  const { setActiveSignupDialog, setActiveLoginDialog } = useGlobalContext();
  const [email, setEmail] = useState<string>("");
  const navigate = useNavigate();
  // Error handling states
  const [error, setError] = useState<string>();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [activeCongratulationPopup, setActiveCongratulationPopup] =
    useState(false);
  const [redirecturl, setRedirecturl] = useState<string>("/pricing");

  const handleEnterKeyPress = () => {
    !isLoading && handleResetPassword();
  };

  // Use the custom hook
  useEnterKeyPress(handleEnterKeyPress);

  const handleResetPassword = async () => {
    if (!email) {
      toastError("Please enter your email address to proceed.");
      return;
    }
    if (!isValidEmail(email)) {
      toastError("Please enter a valid email address.");
      return;
    }
    try {
      let res = await requestResetPassword({ username: email });
      if (res.success) {
        setActiveCongratulationPopup(true);
      } else {
        toastError(
          "Ops! No account found with this email address. Please check and try again."
        );
      }
    } catch (error) {
      console.log("error", error);
    }
  };

  useEffect(() => {}, [props?.activeDialog]);

  const handleBackToLogin = () => {
    props?.handleDialog();
  };
  return (
    props?.activeDialog && (
      <>
        <Box
          sx={{
            position: "fixed",
            top: 0,
            left: 0,
            width: "100vw",
            height: "100vh",
            bgcolor: "rgba(0, 0, 0, 0.60)",
            zIndex: 5000,
          }}
        >
          {/* Apply blur effect to only this background */}
          <Box
            sx={{
              width: "100vw",
              height: "100vh",
              bgcolor: "rgba(0, 0, 0, 0.60)",
              filter: "blur(62px)",
            }}
          />
        </Box>

        <Box
          sx={{
            position: "fixed",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            zIndex: 6000,
            width: "100%",
            maxWidth: "100%",
            maxHeight: "100vh",
            height: "100%",
            overflowY: "auto",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            scrollbarWidth: "none",
            "&::-webkit-scrollbar": { display: "none" },
          }}
        >
          <Box>
            {activeCongratulationPopup && (
              <ResetPasswordRecoveryMailSentPopup
                activeDialog={activeCongratulationPopup}
                setActiveDialog={setActiveCongratulationPopup}
                email={email}
                handleDialog={props.handleDialog}
                redirecturl={redirecturl}
              />
            )}
          </Box>
          <Box
            sx={{
              padding: "40px",
              height: "auto",
              width: { xs: "100%", sm: "100%", lg: "100%" },
              maxWidth: { xs: "100%", sm: "488px", lg: "516px" },
              // maxHeight: "907px",
              bgcolor: "#1B1B1B",
              borderRadius: "24px",
              border: "1px solid #3D3D3D",
              cursor: "default",
              position: "relative",
              overflowY: "scroll",
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              scrollbarWidth: "none",
              margin: "auto",
              "&::-webkit-scrollbar": {
                display: "none",
              },
            }}
          >
            {/* Dialog content */}
            <CloseSharp
              onClick={(e) => {
                e.stopPropagation();
                props?.handleDialog();
              }}
              sx={{
                position: "absolute",
                right: "20px",
                top: "20px",
                cursor: "pointer",
                color: "white",
              }}
            />

            <Box sx={{ marginTop: "4px", marginBottom: "10px" }}>
              <LockIcon />
            </Box>

            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                alignItems: "flex-start",
                width: "100%",
                justifyContent: "center",
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                  gap: "16px",
                  textAlign: "center",
                  width: "100%",
                  mb: "32px",
                }}
              >
                <Typography
                  sx={{
                    fontWeight: "600",
                    color: "text.secondary",
                  }}
                  variant="h3"
                >
                  Reset your password
                </Typography>
                <Typography
                  variant="subtitle1"
                  sx={{
                    fontWeight: "400",
                    color: "rgba(255, 255, 255, 0.5)",
                    fontSize: "18px",
                  }}
                >
                  Don't worry! we've got your back! Just enter your email
                  address and we'll send you a password reset link.
                </Typography>
              </Box>

              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  gap: "32px",
                  width: "100%",
                }}
              >
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    gap: "24px",
                    width: "100%",
                  }}
                >
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      width: "100%",
                      borderRadius: "12px",
                      maxWidth: "480px",
                    }}
                  >
                    <TextInput
                      id="requestResetPasswordPopupInput"
                      lableStyles={{ fontSize: "18px", fontWeight: "600" }}
                      label="Email"
                      placeholder="Your Email"
                      onChange={(e: any) => {
                        setEmail(e.target.value);
                      }}
                      inputStyles={{
                        borderRadius: "14px",
                        height: "54px",
                        color: "#FFFFFF",
                      }}
                      value={email}
                      required
                    />
                    <Typography
                      className="err_field"
                      id="loginIdNotExist"
                      color="red"
                      variant="body2"
                    ></Typography>
                  </Box>
                </Box>

                <Box
                  sx={{
                    width: "100%",
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    gap: "16px",
                  }}
                >
                  <Button
                    id="signinButton"
                    sx={{
                      width: "100%",
                      height: "54px",
                      textAlign: "center",
                      textTransform: "none",
                      bgcolor: "primary.main",
                      color: "#FFFFFF",
                      border: "1px solid #718B08",
                      borderRadius: "14px",
                      paddingY: "16px",
                      "&:hover": {
                        backgroundColor: "primary.main",
                      },
                    }}
                    onClick={() => {
                      handleResetPassword();
                    }}
                  >
                    <Typography
                      sx={{ color: "text.primary", fontWeight: "500" }}
                      variant="subtitle1"
                    >
                      {"Continue"}
                    </Typography>
                  </Button>
                  {error && (
                    <Typography
                      sx={{ width: "100%" }}
                      fontWeight={"400"}
                      variant="caption"
                      color="#E03137"
                    >
                      {error}
                    </Typography>
                  )}
                  <Button
                    id=""
                    sx={{
                      width: "100%",
                      height: "54px",
                      textAlign: "center",
                      textTransform: "none",
                      color: "#FFFFFF",
                      borderRadius: "14px",
                      border: "1px solid #3D3D3D",
                      paddingY: "15px",
                      paddingX: "16px",
                      "&:hover": {
                        backgroundColor: "#161616",
                      },
                    }}
                    onClick={handleBackToLogin}
                  >
                    <Typography
                      sx={{
                        fontSize: "16px",
                        color: "#FFFFFF",
                        fontWeight: "500",
                        lineHeight: "22px",
                      }}
                      variant="subtitle1"
                    >
                      {"Back to Login"}
                    </Typography>
                  </Button>
                </Box>
              </Box>
            </Box>
          </Box>
        </Box>
      </>
    )
  );
};

export default ResetPasswordDialog;
