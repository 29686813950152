import React from 'react'

const PlusIcon = ({color,opacity}) => {
 
  return (
    <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M14.25 8.25H9.75V3.75C9.75 3.55109 9.67098 3.36032 9.53033 3.21967C9.38968 3.07902 9.19891 3 9 3C8.80109 3 8.61032 3.07902 8.46967 3.21967C8.32902 3.36032 8.25 3.55109 8.25 3.75V8.25H3.75C3.55109 8.25 3.36032 8.32902 3.21967 8.46967C3.07902 8.61032 3 8.80109 3 9C3 9.19891 3.07902 9.38968 3.21967 9.53033C3.36032 9.67098 3.55109 9.75 3.75 9.75H8.25V14.25C8.25 14.4489 8.32902 14.6397 8.46967 14.7803C8.61032 14.921 8.80109 15 9 15C9.19891 15 9.38968 14.921 9.53033 14.7803C9.67098 14.6397 9.75 14.4489 9.75 14.25V9.75H14.25C14.4489 9.75 14.6397 9.67098 14.7803 9.53033C14.921 9.38968 15 9.19891 15 9C15 8.80109 14.921 8.61032 14.7803 8.46967C14.6397 8.32902 14.4489 8.25 14.25 8.25Z" fill={color ||"white"} fill-opacity={opacity ||"0.5"}/>    
    </svg>

  )
}

export default PlusIcon
