import { Box, Typography } from "@mui/material";
import React from "react";

type Props = {};

const RefundPolicy = (props: Props) => {
  const paragraphStyle: any = {
    fontWeight: "400",
    color: "rgba(255, 255, 255, 0.5)",
    lineHeight: "24px",
    marginBottom: "12px",
  };

  const headingStyle = {
    marginBottom: "18px",
    color: "white",
    fontWeight: "600",
  };

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        width: "100%",
      }}
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          padding: { xs: "24px", sm: "40px", md: "80px" },
          alignItems: "center",
          gap: { xs: "32px", sm: "60px" },
          width: "100%",
          maxWidth: "862px",
        }}
      >
        {/* -------Header Section------- */}
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: { xs: "flex-start", sm: "center" },
            textAlign: { xs: "start", sm: "center" },
            gap: "16px",
            width: "100%",
          }}
        >
          <Typography
            sx={{
              color: "white",
              fontWeight: "600",
              fontSize: "58px",
              width: "60%",
              lineHeight: "110%",
              background:
                "linear-gradient(81deg, rgba(255,255,255,1) 0%, rgba(48,48,48,1) 100%)",
              WebkitBackgroundClip: "text",
              WebkitTextFillColor: "transparent",
            }}
            variant="h1"
          >
            Refund Policy
          </Typography>
        </Box>

        {/* -------Content section------- */}
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            width: "100%",
            gap: { xs: "24px", sm: "24px" },
          }}
        >
          <Box>
            <Typography variant="subtitle1" style={headingStyle}>
              UI Wiki Privacy Policy
            </Typography>
            <Typography
              variant="subtitle1"
              sx={{ color: "primary.main", fontWeight: "400", mb: "18px" }}
            >
              Effective Date: 15 November 2024
            </Typography>
            <Typography variant="subtitle1" style={paragraphStyle}>
              {`UI Wiki, a product of Omega Orion Pvt. Ltd., is committed to providing you with exceptional design tools and a seamless experience. However, we understand that there might be circumstances where you need a refund. This Refund Policy outlines our practices for handling refund requests. By purchasing a subscription or product from UI Wiki, you agree to this Refund Policy.`}
            </Typography>
          </Box>

          <Typography
            variant="subtitle1"
            style={{ ...headingStyle, margin: "0" }}
          >
            Refund Eligibility
          </Typography>

          <Typography
            variant="subtitle1"
            sx={{ ...paragraphStyle, margin: "0" }}
          >
            Refunds are only provided under the following conditions:
          </Typography>
          <Box sx={{ paddingLeft: "20px" }}>
            <Box
              component="ul"
              sx={{
                marginBottom: "24px",
              }}
            >
              <li style={{ color: "white", marginBottom: "12px" }}>
                <Typography variant="subtitle1" style={headingStyle}>
                  Subscription Plans:
                </Typography>
                <Box
                  component="ul"
                  sx={{ paddingLeft: "20px", marginTop: "10px" }}
                >
                  <li style={{ color: "white" }}>
                    <Typography variant="subtitle1" sx={paragraphStyle}>
                      Refunds are eligible within the first 7 days of the
                      initial purchase if you are dissatisfied with the product
                      or service.
                    </Typography>
                  </li>
                  <li style={{ color: "white" }}>
                    <Typography variant="subtitle1" sx={paragraphStyle}>
                      Refunds are not available for renewals or recurring
                      subscription charges once the billing cycle begins.
                    </Typography>
                  </li>
                </Box>
              </li>
            </Box>

            <Box
              component="ul"
              sx={{
                marginBottom: "24px",
              }}
            >
              <li style={{ color: "white", marginBottom: "12px" }}>
                <Typography variant="subtitle1" style={headingStyle}>
                  Lifetime Plans:
                </Typography>
                <Box
                  component="ul"
                  sx={{ paddingLeft: "20px", marginTop: "10px" }}
                >
                  <li style={{ color: "white" }}>
                    <Typography variant="subtitle1" sx={paragraphStyle}>
                      Refunds are eligible within the first 14 days of purchase
                      if the product has not met your expectations.
                    </Typography>
                  </li>
                </Box>
              </li>
            </Box>

            <Box
              component="ul"
              sx={{
                marginBottom: "24px",
              }}
            >
              <li style={{ color: "white", marginBottom: "12px" }}>
                <Typography variant="subtitle1" style={headingStyle}>
                  Promotional Offers:
                </Typography>
                <Box
                  component="ul"
                  sx={{ paddingLeft: "20px", marginTop: "10px" }}
                >
                  <li style={{ color: "white" }}>
                    <Typography variant="subtitle1" sx={paragraphStyle}>
                      Refunds are not applicable to purchases made during
                      promotions, discounts, or sales unless explicitly stated
                      otherwise.
                    </Typography>
                  </li>
                </Box>
              </li>
            </Box>
          </Box>
          <Box>
            <Typography variant="subtitle1" style={headingStyle}>
              Refund Process
            </Typography>
            <Typography
              variant="subtitle1"
              sx={{ ...paragraphStyle, margin: "0" }}
            >
              To request a refund, follow these steps:
            </Typography>
          </Box>
          <Box sx={{ paddingLeft: "20px" }}>
            <Box>
              <Typography variant="subtitle1" style={headingStyle}>
                Contact Us:
              </Typography>
              <Box
                component="ul"
                sx={{ paddingLeft: "20px", marginBottom: "24px" }}
              >
                <li style={{ color: "white", marginBottom: "12px" }}>
                  <Typography variant="subtitle1" sx={paragraphStyle}>
                    Send an email to support@uiwiki.co with the subject line:
                    "Refund Request."
                  </Typography>
                </li>
                <li style={{ color: "white", marginBottom: "12px" }}>
                  <Typography variant="subtitle1" sx={paragraphStyle}>
                    Include your order ID, purchase date, and the reason for the
                    refund
                  </Typography>
                </li>
              </Box>
            </Box>
            <Box>
              <Typography variant="subtitle1" style={headingStyle}>
                Evaluation:
              </Typography>
              <Box
                component="ul"
                sx={{ paddingLeft: "20px", marginBottom: "24px" }}
              >
                <li style={{ color: "white", marginBottom: "12px" }}>
                  <Typography variant="subtitle1" sx={paragraphStyle}>
                    Our team will review your request and respond within 5
                    business days.
                  </Typography>
                </li>
              </Box>
            </Box>
            <Box>
              <Typography variant="subtitle1" style={headingStyle}>
                Approval or Denial:
              </Typography>
              <Box
                component="ul"
                sx={{ paddingLeft: "20px", marginBottom: "24px" }}
              >
                <li style={{ color: "white", marginBottom: "12px" }}>
                  <Typography variant="subtitle1" sx={paragraphStyle}>
                    If approved, the refund will be processed within 7-10
                    business days.
                  </Typography>
                </li>
                <li style={{ color: "white", marginBottom: "12px" }}>
                  <Typography variant="subtitle1" sx={paragraphStyle}>
                    If denied, we will provide a detailed explanation.
                  </Typography>
                </li>
              </Box>
            </Box>
          </Box>
          <Box>
            <Typography variant="subtitle1" style={headingStyle}>
              Non-Refundable Scenarios
            </Typography>
            <Typography variant="subtitle1" sx={paragraphStyle}>
              {`Refunds will not be issued in the following cases:`}
            </Typography>
            <Box
              component="ul"
              sx={{ paddingLeft: "20px", marginBottom: "24px" }}
            >
              <li style={{ color: "white", marginBottom: "12px" }}>
                <Typography variant="subtitle1" sx={paragraphStyle}>
                  If you have used or downloaded significant resources from UI
                  Wiki.
                </Typography>
              </li>
              <li style={{ color: "white", marginBottom: "12px" }}>
                <Typography variant="subtitle1" sx={paragraphStyle}>
                  If the request is made after the refund eligibility period.
                </Typography>
              </li>
              <li style={{ color: "white", marginBottom: "12px" }}>
                <Typography variant="subtitle1" sx={paragraphStyle}>
                  If you violate any terms outlined in our [Terms of Use].
                </Typography>
              </li>
              <li style={{ color: "white", marginBottom: "12px" }}>
                <Typography variant="subtitle1" sx={paragraphStyle}>
                  Technical issues caused by third-party services or your local
                  setup (we’re happy to assist in resolving these issues, but
                  they do not qualify for refunds).
                </Typography>
              </li>
            </Box>
          </Box>
          <Box>
            <Typography variant="subtitle1" style={headingStyle}>
              Payment Gateway Fees
            </Typography>
            <Typography variant="subtitle1" sx={paragraphStyle}>
              Refunds will be processed for the subscription or product amount
              only. Payment gateway fees (e.g., Paddle transaction fees) are
              non-refundable and will be deducted from the total amount.
            </Typography>
          </Box>
          <Box>
            <Typography variant="subtitle1" style={headingStyle}>
              Partial Refunds
            </Typography>
            <Typography variant="subtitle1" sx={paragraphStyle}>
              For subscription plans, partial refunds may be issued at our
              discretion based on the usage of the service and time elapsed
              since purchase.
            </Typography>
          </Box>
          <Box>
            <Typography variant="subtitle1" style={headingStyle}>
              Modifications to the Refund Policy
            </Typography>
            <Typography variant="subtitle1" sx={paragraphStyle}>
              We reserve the right to update or modify this Refund Policy at any
              time. Changes will be effective immediately upon posting on our
              website. It is your responsibility to review the policy
              periodically.
            </Typography>
          </Box>
          <Box>
            <Typography variant="subtitle1" style={headingStyle}>
              Contact Us
            </Typography>
            <Typography variant="subtitle1" sx={paragraphStyle}>
              {`If you have any questions or concerns regarding this Refund Policy, feel free to reach out:`}
            </Typography>
            <Box
              component="ul"
              sx={{ paddingLeft: "20px", marginBottom: "24px" }}
            >
              <li style={{ color: "white", marginBottom: "12px" }}>
                <Typography variant="subtitle1" sx={paragraphStyle}>
                  Email: support@uiwiki.co
                </Typography>
              </li>
              <li style={{ color: "white", marginBottom: "12px" }}>
                <Typography variant="subtitle1" sx={paragraphStyle}>
                  Address: UI Wiki, Omega Orion Pvt. Ltd., 2541, Phase 11,
                  Sector 65, Mohali, India - 160062
                </Typography>
              </li>
            </Box>
          </Box>
        </Box>
        <Typography
          variant="subtitle1"
          sx={{
            color: "rgba(255, 255, 255, 0.5)",
            fontWeight: "400",
          }}
        >
          By using UI Wiki, you acknowledge that you have read, understood, and
          agree to this Refund Policy.
        </Typography>
      </Box>
    </Box>
  );
};

export default RefundPolicy;
