import React, { useState } from "react";
import { Box, Typography } from "@mui/material";
import PopupDialog from "../../../../../shared/components/PopupScreen/Dialog";
import {
  ButtonPrimary,
  ButtonSecondary,
} from "../../../../../shared/components/button/Buttons";

interface DeleteDialogProps {
  deleteAction: () => void;
  title: string;
  setIsDeleteDialogVisible?: any;
}
const DeleteDialog = (props: DeleteDialogProps) => {
  const [showLoading, setShowLoading] = useState<boolean>(false);

  const handleDelete = () => {
    setShowLoading(true);
    props?.deleteAction();
  };

  return (
    <PopupDialog
      style={{
        width: "376px !important",
        maxHeight: "fi-content",
        height: "100%",
        overflow: "hidden !important",
        display: "flex",
        gap: "36px",
        justifyContent: "center",
        backgroundColor: "#1B1B1B",
        boxShadow: "inset 0px 0px 0px 1px #3D3D3D",
      }}
      open={true}
      title="Delete"
      titleStyle={{
        color: "white",
      }}
      onClose={() => {
        props?.setIsDeleteDialogVisible(false);
      }}
      children={
        <Box
          className="HideScrollbar"
          sx={{
            display: "flex",
            gap: "36px",
            flexDirection: "column",
            width: "100%",
            overflow: "scroll",
          }}
        >
          <Typography
            sx={{ textAlign: "center", color: "rgba(255, 255, 255, 0.5)" }}
            variant="body2"
          >
            {props?.title}
          </Typography>
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              gap: "10px",
              justifyContent: "center",
            }}
          >
            <ButtonSecondary
              onClick={() => {
                props?.setIsDeleteDialogVisible(false);
              }}
              LabelStyle={{ fontSize: "14px !important", fontWeight: "600" }}
              sx={{
                borderRadius: "30px !important",
                border: "1px solid rgba(0, 0, 0, 0.20)",
                width: "115px",
                height: "52px",
              }}
              label="Cancel"
            />
            <ButtonPrimary
              onClick={() => {
                handleDelete();
              }}
              isLoading={showLoading}
              loadingColor = {"#FFFFFF"}
              LabelStyle={{ fontSize: "14px !important", fontWeight: "600" }}
              sx={{
                borderRadius: "30px !important",
                bgcolor: "#E03137",
                width: "115px",
                color: "white",
                height: "52px",
                ":hover": {
                  backgroundColor: "#E03137",
                  boxShadow: "none",
                },
                ":disabled": {
                  boxShadow: "none",
                  backgroundColor: "#E03137",
                },
              }}
              label="Delete"
            />
          </Box>
        </Box>
      }
    />
  );
};

export default DeleteDialog;
