import React from 'react'

const EyeOffIcon = () => {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M17.5 6.85548C19.7232 8.48958 21 10.792 21 12C21 14 17.5 19 12 19C10.0636 19 8.37512 18.3802 7 17.4898M14 5.22655C13.3678 5.08102 12.7001 5 12 5C6.5 5 3 10 3 12C3 12.73 3.46629 13.8597 4.32592 15" stroke="white" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M9.87868 14.1218C11.0503 15.2933 12.9497 15.2933 14.1213 14.1218C15.2929 12.9502 15.2929 11.0507 14.1213 9.87913M11.5 9.04217C10.9063 9.142 10.3368 9.42099 9.87868 9.87913C9.42054 10.3373 9.14155 10.9067 9.04172 11.5005" stroke="white" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M4 20L20 4" stroke="white" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
</svg>

  )
}

export default EyeOffIcon
