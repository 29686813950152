import { useEffect, useState } from "react";
import { Box, useTheme } from "@mui/material";
import { useSettingsContext } from "../Setting";
import LoadingIndicator from "../../../shared/Loading/LoadingIndicator";
import Profile from "../tabs/profile/Profile";
import Security from "../tabs/security/Security";
import Membership from "../tabs/membership/Membership";
import Payment from "../tabs/payment/Payment";
import Billing from "../tabs/billing/Billing";
import Notifications from "../tabs/notifications/Notifications";
import DefaultLoading from "../../../shared/Loading/DefaultLoading";
import PromoCodeInSettings from "../tabs/PromoCode/PromoCodeInSettings";

const TOTAL_TABS = 3;

const SettingsRoutes: React.FC = () => {
  const theme = useTheme();
  const { activeTab, setActiveTab } = useSettingsContext();
  const [showLoading, setShowLoading] = useState(false);

  useEffect(() => {
    const handleKeyDown = (event: KeyboardEvent) => {
      if (event.key === "Tab") {
        event.preventDefault(); // Tab ka default behavior rokenge
        setActiveTab((prevTab: number) =>
          prevTab === TOTAL_TABS ? 1 : prevTab + 1
        );
      } else if (event.key === "Enter") {
        // Enter ka default behavior aur propagation rokenge
        event.preventDefault();
        event.stopPropagation();
      }
    };

    window.addEventListener("keydown", handleKeyDown);
    return () => window.removeEventListener("keydown", handleKeyDown);
  }, [setActiveTab]);

  return (
    <Box
      className="HideScrollbar"
      display="flex"
      // width="100%"
      maxWidth="100%"
      // sx={{ padding: "48px 56px" }}
    >
      {showLoading ? (
        <Box
          sx={{
            width: "42px",
            height: "42px",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <DefaultLoading />
        </Box>
      ) : (
        <Box width="100%">
          <Box
            sx={{
              // padding: "24px",
              width: "100%",
            }}
          >
            {activeTab === 1 ? (
              <Profile />
            ) : activeTab === 2 ? (
              <Membership />
            ) : activeTab === 3 ? (
              <Security />
            ) : (
              // ) : activeTab === 4 ? (
              // <Payment />
              // <PromoCodeInSettings/>
              // ) : activeTab === 5 ? (
              //   <Billing />
              // ) : (
              //   activeTab === 6 && <Notifications />
              setActiveTab(1)
            )}
          </Box>
        </Box>
      )}
    </Box>
  );
};

export default SettingsRoutes;
