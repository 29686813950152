import React, { useEffect } from "react";
import { Box, Typography } from "@mui/material";
import { CloseSharp, Height } from "@mui/icons-material";
import { useNavigate } from "react-router-dom";
import { ImageContainer } from "../../shared/components/ImageContainer";
import { SignUp_Happy } from "../../assets/images/image";
import {
  ButtonPrimary,
  ButtonSecondary,
} from "../../shared/components/button/Buttons";
import { oneMonthFreeTrial } from "../../services/authentication";
import { getUserIdFromCookies } from "../../utils/extensions";
import { toastSuccess } from "../../utils/ToastMessage";

type Props = {
  activeDialog: any;
  handleDialog?: any;
  setActiveDialog: any;
};

const HomeCongratulationPopUp: React.FC<Props> = (props) => {
  const userId = getUserIdFromCookies();
  const navigate = useNavigate();

  const handleOneMonthFreeTrial = async () => {
    // await oneMonthFreeTrial({ userId })
    //   .then(() => {
    //     toastSuccess("Success", "Free Subscription Activated.");
    //     props?.setActiveDialog(false);
    //   })
    //   .catch((err) => console.log("err", err));
  };
  useEffect(() => {}, [props?.activeDialog]);

  return (
    props?.activeDialog && (
      <>
        <Box
          sx={{
            position: "fixed",
            top: 0,
            left: 0,
            width: "100vw",
            height: "100vh",
            bgcolor: "rgba(0, 0, 0, 0.60)",
            zIndex: 5000,
          }}
        >
          {/* Apply blur effect to only this background */}
          <Box
            sx={{
              width: "100vw",
              height: "100vh",
              border: "1px solid red",
              bgcolor: "rgba(0, 0, 0, 0.60)",
              filter: "blur(62px)",
            }}
          />
        </Box>

        <Box
          sx={{
            position: "fixed",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            zIndex: 6000,
          }}
        >
          <Box
            sx={{
              width: "743px",
              padding: "40px",
              height: "auto",
              bgcolor: "#1B1B1B",
              borderRadius: "24px",
              border: "1px solid #3D3D3D",
              cursor: "default",
              position: "relative",
            }}
          >
            {/* Dialog content */}
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                width: "100%",
                justifyContent: "center",
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  width: "100%",
                  justifyContent: "space-between",
                }}
              >
                <CloseSharp
                  onClick={(e) => {
                    e.stopPropagation();
                    props?.setActiveDialog(false);
                  }}
                  sx={{
                    position: "absolute",
                    right: "40px",
                    top: "40px",
                    cursor: "pointer",
                    color: "white",
                  }}
                />
              </Box>

              <ImageContainer
                title={"Cracker Icon"}
                height={"200px"}
                width={"200px"}
              >
                {SignUp_Happy}
              </ImageContainer>

              <Box
                sx={{
                  height: "50%",
                  width: "557px",
                  display: "flex",
                  flexDirection: "column",
                  gap: "32px",
                }}
              >
                <Box
                  sx={{
                    width: "100%",
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <Typography
                    sx={{
                      fontSize: "32px",
                      fontWeight: "600",
                      color: "#FFFFFF",
                      mb: "12px",
                      textAlign: "center",
                    }}
                  >
                    Get 1 Month Free!
                  </Typography>
                  <Typography
                    sx={{
                      fontSize: "18px",
                      fontWeight: "400",
                      color: "#FFFFFF",
                      opacity: "50%",
                      padding: "0 41px",
                      textAlign: "center",
                    }}
                  >
                    Kickstart your creative journey with 1 month of premium
                    access - absolutely free, no credit card needed!
                  </Typography>
                </Box>
                <Box
                  sx={{
                    display: "flex",
                    height: "300%",
                    width: "100%",
                    justifyContent: "center",
                    alignItems: "center",
                    gap: "10px",
                  }}
                >
                  <ButtonSecondary
                    label="Dismiss"
                    sx={{ width: "35%" }}
                    onClick={() => {
                      props?.setActiveDialog(false);
                    }}
                  />
                  <ButtonPrimary
                    label="Try now for $0"
                    sx={{
                      width: "35%",
                      fontSize: "18px",
                      fontWeight: "600",
                      lineHeight: "22px",
                      color: "#000000",
                    }}
                    onClick={() => {
                      handleOneMonthFreeTrial();
                    }}
                  />
                </Box>
              </Box>
            </Box>
          </Box>
        </Box>
      </>
    )
  );
};

export default HomeCongratulationPopUp;
