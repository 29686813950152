import { Box, Typography } from "@mui/material";
import React from "react";
import Chip from "../../shared/components/Chip";
import NewsletterSection from "../../main/NewsletterSection";
import FaqQuestion from "./FaqQuestion";
import { faqs } from "../../utils/constants";

type Props = {};

const FaqScreen = (props: Props) => {
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        padding: { xs: "24px", sm: "40px", md: "80px" },
        alignItems: "center",
        gap: { xs: "40px", md: "80px" },
        width: "100%",
      }}
    >
      {/* ------Header Section------ */}
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          gap: "24px",
          textAlign: "center",
          alignItems: "center",
        }}
      >
        <Chip
          sx={{
            height: "48px",
            borderRadius: "79px",
            width: "182px",
          }}
          title="Explore Our FAQs"
        />
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            textAlign: "center",
            gap: "16px",
          }}
        >
          <Typography
            sx={{
              color: "white",
              fontWeight: "600",
              fontSize: "58px",
              width: "100%",
              lineHeight: "110%",
              background:
                "linear-gradient(81deg, rgba(255,255,255,1) 0%, rgba(48,48,48,1) 100%)",
              WebkitBackgroundClip: "text",
              WebkitTextFillColor: "transparent",
            }}
            variant="h1"
          >
            Your Guide to UI Wiki
          </Typography>
          <Typography
            variant="h4"
            sx={{
              color: "rgba(255, 255, 255, 0.5)",
              fontWeight: "400",
              fontSize: "18px !important",
            }}
          >
            Find quick answers to help you get the most out of UI Wiki.
          </Typography>
        </Box>
      </Box>

      {/* ------Content Section------ */}
      <>
        {/* -----General----- */}
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            gap: "32px",
            width: "100%",
            maxWidth: "900px",
          }}
        >
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              gap: "16px",
              width: "100%",
            }}
          >
            {faqs?.map((faq: any) => (
              <FaqQuestion
                question={faq?.question}
                description={faq?.description}
              />
            ))}
          </Box>
        </Box>
      </>

      {/* -----Newsletter Section----- */}
      <Box sx={{ width: "100%" }}>
        <NewsletterSection />
      </Box>
    </Box>
  );
};

export default FaqScreen;
